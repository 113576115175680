
:root {
    --main-color: #8fe211;
    --main-color-hover: #95df25;
	
	--main-color-dark: #7cc214;
	--main-color-dark-hover: #8ad916;
  
    --secondary-color:#E27D35;
    --secondary-color-hover:#FF964B;
  
    --main-header-color:#ffffff;
    --main-text-color:#C2C2C2;
    --strong-color:#ffffff;
  
    --body-bg:#0A0A0A;
    --dark-bg:#000000;
  }
  
  
  
  
  @font-face {
      font-family: 'DIN Next LT Pro';
      src: url('../webfonts/DINNextLTPro-Medium.eot');
      src: url('../webfonts/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
          url('../webfonts/DINNextLTPro-Medium.woff2') format('woff2'),
          url('../webfonts/DINNextLTPro-Medium.woff') format('woff'),
          url('../webfonts/DINNextLTPro-Medium.ttf') format('truetype'),
          url('../webfonts/DINNextLTPro-Medium.svg#DINNextLTPro-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
  }
  
  
  @font-face {
      font-family: 'DIN Next LT Pro';
      src: url('../webfonts/DINNextLTPro-Bold.eot');
      src: url('../webfonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
          url('../webfonts/DINNextLTPro-Bold.woff2') format('woff2'),
          url('../webfonts/DINNextLTPro-Bold.woff') format('woff'),
          url('../webfonts/DINNextLTPro-Bold.ttf') format('truetype'),
          url('../webfonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
      font-weight: 600;
      font-style: normal;
  }
  
  
  @font-face {
      font-family: 'DIN Next LT Pro';
      src: url('../webfonts/DINNextLTPro-Bold.eot');
      src: url('../webfonts/DINNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
          url('../webfonts/DINNextLTPro-Bold.woff2') format('woff2'),
          url('../webfonts/DINNextLTPro-Bold.woff') format('woff'),
          url('../webfonts/DINNextLTPro-Bold.ttf') format('truetype'),
          url('../webfonts/DINNextLTPro-Bold.svg#DINNextLTPro-Bold') format('svg');
      font-weight: 700;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'DIN Next LT Pro';
      src: url('../webfonts/DINNextLTPro-Heavy.eot');
      src: url('../webfonts/DINNextLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
          url('../webfonts/DINNextLTPro-Heavy.woff2') format('woff2'),
          url('../webfonts/DINNextLTPro-Heavy.woff') format('woff'),
          url('../webfonts/DINNextLTPro-Heavy.ttf') format('truetype'),
          url('../webfonts/DINNextLTPro-Heavy.svg#DINNextLTPro-Heavy') format('svg');
      font-weight: 900;
      font-style: normal;
  }
  
  
  /* Eric Meyer's CSS Reset */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  body {
      line-height: 1;
  }
  ol, ul {
      list-style: none;
  }
  blockquote, q {
      quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }
  /* End of Eric Meyer's CSS Reset */
  
  article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
      display: block;
  }
  
  
  body {
      font-family: 'Titillium Web', 'ProximaNova', 'Proxima Nova', Verdana, Arial, Helvetica, sans-serif;
      font-size: 16px;
      line-height: 26px;
      color:var(--main-text-color);
      font-weight: 400;
      background:var(--body-bg);
  }
  
  .CookieConsent {
	z-index: 9999 !important;
  }
  
  .page-game-servers {
    background:url('../images/bg-gameservers.webp') top center no-repeat var(--body-bg);
  }
  
  .page-dedicated {
    background:url('../images/bg-dedicated.webp') top -40px center no-repeat var(--body-bg);
  }
  
  .page-hosting {
    background:url('../images/bg-hosting.webp') top -80px center no-repeat var(--body-bg);
  }
  
  .page-voice {
    background:url('../images/bg-voice.webp') top -60px center no-repeat var(--body-bg);
  }
  
  .page-cloud {
    background:url('../images/bg-cloud.webp') top center no-repeat var(--body-bg);
  }
  
  .page-game-inner {
	background-position: top center;
	background-color: var(--body-bg);
	background-repeat: no-repeat;
  }
  
  .page-about {
    background:url('../images/bg-about.webp') top center no-repeat var(--body-bg);
  }
  
  .page-affiliates {
    background:url('../images/bg-affiliate.webp') top center no-repeat var(--body-bg);
  }
  
  .back-nav-games {
	margin-top: -78px;
	margin-left: 15px;
  }

  .game-tags {
	height: 50px;
	margin-top: -20px;
  }
  
  .game-tag-ea, .game-tag-steam, .game-tag-steamepic {
    font-size: 12px;
    background-color: #d53f3f;
    color: #fff;
    font-weight: 600;
    padding: 3px 7px 1px 7px;
    border-radius: 5px;
    line-height: 18px;
    margin-right: 10px;
    margin-top: 13px;
  }

  .game-tag-ea:hover, .game-tag-steam:hover, .game-tag-steamepic:hover {
    background-color: #a71e1e;
    color: #fff;
  }
  
  #hero .container {
    position: relative;
  }
  
  .hero-bgs {
    position: absolute;
    z-index: -1;
    content: " ";
    width: 100%;
    top: 0px;
    bottom: 0px;
    margin-bottom: -200px;
	margin-left: 200px;
	background-position: top;
	background-repeat: no-repeat;
	background-color: var(--body-bg);
  }
  .hero-bgs.cs2 {
    background-image:url('../images/banner-bg-cs2.webp');
  }
  .hero-bgs.icarus {
    background-image:url('../images/banner-bg-icarus.webp');
  }
  .hero-bgs.dedi-1 {
    background-image:url('../images/banner-bg-dedi.webp');
  }
  .hero-bgs.vrising {
    background-image:url('../images/banner-bg-vrising.webp');
  }
  .hero-bgs.sevendays {
    background-image:url('../images/banner-bg-minecraft.webp');
  }
  .hero-bgs.thefront {
    background-image:url('../images/banner-bg-thefront.webp');
  }
  .hero-bgs.steamsummersale24 {
    background-image:url('../images/banner-bg-steam-summer-sale-24.webp');
  }
  .hero-bgs.satisfactory {
    background-image:url('../images/banner-bg-satisfactory.webp');
  }
  .satisfactory-text {
	  color:#f8a034;
	  text-shadow:1px 1px 11px #9b5704, 1px 1px 3px #7b4400;
  }
  .hero-bgs.duckside {
    background-image:url('../images/banner-bg-duckside.webp');
  }
  .duckside-text {
	  color:#f38320;
  }
  .hero-bgs.palworld {
    background-image:url('../images/banner-bg-palworld.webp');
  }
  .palworld-text {
	  color:#fafcff;
	  text-shadow:1px 1px 11px #389ccc,1px 1px 3px #37bdc4;
  }
  .hero-bgs.blackfriday24 {
    background-image:url('../images/banner-bg-blackfriday24.webp');
  }
  .hero-bgs.xmas24 {
    background-image:url('../images/banner-bg-xmas24.webp');
  }
  .hero-bgs.moria {
    background-image:url('../images/banner-bg-moria.webp');
  }
  
  body,html {
    overflow-x:hidden;
  }
  
  
   @media only screen and (min-width: 1240px) and (max-width: 1439px)  {
  
  
    .page-home {
      background:url('../images/banner-bg.webp') top right -5% no-repeat var(--body-bg);
    }
  
    .page-game-servers {
      background:url('../images/bg-gameservers.webp') top center no-repeat var(--body-bg);
    }
  
    .page-dedicated {
      background:url('../images/bg-dedicated.webp') top -40px center no-repeat var(--body-bg);
    }
  
  
    .page-hosting {
      background:url('../images/bg-hosting.webp') top -40px center no-repeat var(--body-bg);
    }
  
    .page-voice {
      background:url('../images/bg-voice.webp') top -60px center no-repeat var(--body-bg);
    }
  
    .page-cloud {
      background:url('../images/bg-cloud.webp') top center no-repeat var(--body-bg);
    }
  
    .page-game-inner {
      background-position: top center;
      background-color: var(--body-bg);
      background-repeat: no-repeat;
    }
  
    .page-about {
      background:url('../images/bg-about.webp') top center no-repeat var(--body-bg);
    }
  
    .page-affiliates {
      background:url('../images/bg-affiliate.webp') top center no-repeat var(--body-bg);
    }
  
    .location-nz-info {
      margin-left:-50px !important;
    }
  
    .location-nz-info.pin-hover:after,
    .location-nz-info.pin-hover:before {
      margin-left:62px !important;
    }
  
    .location-syd-info {
      margin-left:-50px !important;
    }
  
    .location-syd-info.pin-hover:after,
    .location-syd-info.pin-hover:before {
      margin-left:62px !important;
    }
  
    .location-bris-info {
      margin-left:-50px !important;
    }
  
    .location-bris-info.pin-hover:after,
    .location-bris-info.pin-hover:before {
      margin-left:62px !important;
    }
  
    .location-mel-info {
      margin-left:-50px !important;
    }
  
    .location-mel-info.pin-hover:after,
    .location-mel-info.pin-hover:before {
      margin-left:62px !important;
    }
  
    .location-jp-info {
      margin-left:-50px !important;
    }
  
    .location-jp-info.pin-hover:after,
    .location-jp-info.pin-hover:before {
      margin-left:62px !important;
    }
	
    .location-hk-info {
      margin-left:-50px !important;
    }
  
    .location-hk-info.pin-hover:after,
    .location-hk-info.pin-hover:before {
      margin-left:62px !important;
    }
  
  }
  
  .wrapper {
      width:100%;
      max-width: 1280px;
      margin: 0 auto;
  }
  
  p {
      padding-bottom: 1rem;
  }
  
  
  h1,
  h2,
  h3,
  h4,
  h5 {
      color: #fff;
      font-weight:500;
      font-family:"Din Next LT Pro", "Roboto",sans-serif;
  }
  
  img {
      line-height: 1;
      vertical-align: bottom;
  }
  
  h1 {
      font-size: 44px;
      line-height: 52px;
      font-weight:600;
  }
  
  h2 {
      font-size: 36px;
      line-height: 42px;
      padding-bottom: 6px;
      font-weight:700;
  }
  
  h2 small {
    font-size:14px;
    font-family:"Titillium Web",sans-serif;
    color:rgba(194,194,194,0.5);
    display:block;
    text-transform: uppercase;
    padding-bottom:3px;
    font-weight:600;
    letter-spacing:1px;
  }
  
  h3 {
      font-size: 24px;
      line-height: 32px;
  }
  
  h4 {
      font-size: 20px;
      line-height:28px;
  }
  
  h5 {
      font-size: 18px;
      line-height: 24px;
  }
  
  a {
      color: var(--main-color);
      font-weight: 500;
      text-decoration: none;
  }
  
  a:hover,
  a:active,
  a:focus {
      color: var(--main-color-hover);
      text-decoration: none;
  }
  
  section {
      padding: 88px 0px;
  }
  
  
  .semibold {
    font-weight:500;
  }
  
  .bold {
    font-weight:600;
  }
  
  .strong, .extrabold {
    font-weight:700;
  }
  
  .black {
    font-weight:900;
  }
  
  .font-10 {
    font-size:10px;
    line-height:22px;
  }
  
  .font-11 {
    font-size:11px;
    line-height:22px;
  }
  
  .font-12 {
    font-size:12px;
    line-height:24px;
  }
  
  .font-13 {
    font-size:13px;
    line-height:24px;
  }
  .font-14 {
    font-size:14px;
    line-height:24px;
  }
  
  .font-15 {
    font-size:15px;
    line-height:24px;
  }
  .font-16 {
    font-size:16px;
    line-height:24px;
  }
  
  .font-18 {
    font-size:18px !important;
    line-height:28px !important;
  }
  
  .font-22 {
    font-size:22px;
    line-height:32px;
  }
  
  .font-24 {
    font-size:24px;
    line-height:32px;
  }
  
  .font-30 {
    font-size:30px;
    line-height:38px;
  }
  
  .font-36 {
    font-size:36px;
    line-height:44px;
  }
  
  .font-40 {
    font-size:40px;
    line-height:48px;
  }
  
  .font-48 {
    font-size:48px;
    line-height:56px;
  }
  
  .font-50 {
    line-height:50px;
    font-size:50px;
  }
  
  .font-55{
    font-size:55px;
    line-height:64px;
  }
  
  .font-60 {
    font-size:60px;
    line-height:68px;
  }
  
  .font-68 {
    font-size:68px;
    line-height:72px;
  }
  
  i.font-40 {
    line-height:40px;
    min-width:40px;
    text-align:center;
  }
  
  i.font-44 {
    line-height:44px;
    min-width:44px;
    text-align:center;
  }
  
  i.font-48, .location-feature i.font-40 {
    line-height:48px;
    min-width:48px;
    text-align:center;
  }
  
  i.font-50 {
    line-height:50px;
    min-width:50px;
    text-align:center;
  }
  
  i.font-55 {
    line-height:55px;
    min-width:55px;
    text-align:center;
  }
  
  i.font-60 {
    line-height:60px;
    min-width:60px;
    text-align:center;
  }
  
  .hero-text-left p {
    margin:0px !important;
    max-width:100%;
    text-align:left;
  }
  
  .hero-text-left, .hero-text-left h1 {
    text-align:left;
  }
  
  .green {
    color:var(--main-color);
  }
  
  .orange {
    color:var(--secondary-color);
  }
  
  .red {
	color: #d92a2a;
  }
  
  * {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
  }
  
  .clearfix:after,
  .container:after,
  .input-group:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0
  }
  
  .container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
  }
  
  strong {
      color: var(--strong-color);
      font-weight: 600;
  }
  
  *:hover, * {
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
  }
  
  p {
      padding-bottom: 20px;
  }
  
  
  
  .title {
      padding-bottom: 40px;
      text-align: center;
      margin-top: -6px;
  }
  
  .titleleft {
      text-align: left;
  }
  
  .title p {
      font-size: 20px;
      line-height: 26px;
      font-weight:500;
      color:#fff;
      max-width: 680px;
      margin: 0 auto;
      padding-bottom: 0px;
      padding-top: 10px;
  }
  
  .flex-grid .title {
      padding-left: 0px;
      padding-right: 0px;
  }
  
  .button {
      padding: 0px 32px;
      display: inline-block;
      height: 48px;
      line-height: 50px;
      color: #fff !important;
      background: var(--main-color-dark);
	  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.37), inset 0 -2px 0 0 rgba(0,0,0,0.21);
      font-size: 14px;
      font-weight: 600;
      font-family:"Din Next LT Pro", "Roboto",sans-serif;
      text-transform: uppercase;
      box-shadow: 0 2px 2px 0 rgba(20,20,45,0.12), inset 0 -2px 0 0 rgba(0,0,0,0.10);
      border-radius: 10px;
      text-shadow:0px -1px rgba(0,0,0,0.1);
  }
  
  .out-of-stock {
	background-color: #333;
	cursor: default;
  }
  
  .out-of-stock:hover {
	background-color: #222 !important;
	cursor: default !important;
  }
  
  .button.large {
    height:56px;
    line-height:58px;
    font-size:16px;
    padding:0px 32px;
    border-radius: 12px;
  }
  
  
  .button:hover {
      color: #fff !important;
      text-decoration: none;
      background: var(--main-color-dark-hover);
  }
  
  .button:focus {
      transform: scale(0.97);
      outline: none;
  }
  
  .button.outline {
      background: transparent;
      box-shadow: none;
      border: 1px solid #fff;
      color: #fff;
  }
  
  .button.outline:hover {
      background: #fff;
      color: #363636!important;
      box-shadow: none;
      text-shadow:none;
  }
  
  .button:hover i, .button i {
    transition: none;
  }
  
  .button.secondary {
    background:var(--secondary-color);
  }
  
  .button.secondary:hover {
    background:var(--secondary-color-hover);
  }
  
  .button.disabled {
      cursor: not-allowed;
      opacity: 0.5;
  }
  
  .button.disabled:hover {
    opacity:0.7;
  }
  
  .button.dark {
  background-image: linear-gradient(270deg, #181818 0%, #0A0A0A 100%);
  }
  
  .button.dark:hover {
    background-image: linear-gradient(270deg, #252525 0%, #131313 100%);
  }
  
  /* Responsive Nav */
  
  
  
  .button_container {
      position: absolute;
      top: 40px;
      right: 20px;
      height: 20px;
      width: 28px;
      cursor: pointer;
      z-index: 1008;
      -webkit-transition: opacity .25s ease;
      transition: opacity .25s ease;
      display:none;
  }
  
  .fixed-header .button_container{
    top:31px;
    right:20px;
  }
  
  .button_container.active {
      height: 22px
  }
  
  .button_container.active span:nth-of-type(3) {
      top: 20px !important
  }
  
  .button_container.active span {
      top: -2px !important
  }
  
  .button_container:hover span {
      background: var(--main-color)
  }
  
  .button_container.active .top {
      -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: var(--main-color)
  }
  
  .button_container.active .middle {
      opacity: 0;
      background: var(--main-color)
  }
  
  .button_container.active .bottom {
      -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: var(--main-color)
  }
  
  .fixed {
      position: fixed;
  }
  
  .button_container span {
      background: #fff;
      border: none;
      height: 3px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: all .35s ease;
      transition: all .35s ease;
      cursor: pointer;
      z-index: 1008;
      border-radius: 4px;
  }
  
  .button_container span:nth-of-type(2) {
      top: 8px
  }
  
  .button_container span:nth-of-type(3) {
      top: 16px
  }
  
  
  .bottomoverlay {
      position: absolute;
      padding: 0 20px;
      bottom: 28px;
      text-align: center;
      left: 0;
      right: 0
  }
  
  .bottomoverlay .button {
      width: 48%;
      float: right;
      padding: 0
  }
  
  .bottomoverlay .outlinewhite {
      float: left
  }
  
  
  .text-center {
      text-align: center;
  }
  
  .text-lg {
      font-size:1.25rem;
      line-height:1.75rem;
  }
  
  .text-md {
      font-size:1.125rem;
      line-height:1.5rem;
  }
  
  .width-60 {
      width:60%;
  }
  
  .width-75 {
      width:75%;
  }
  
  .pull-left-img {
      float:left;
      margin-right:2rem;
  }
  
  .text-left {
      text-align:left;
  }
  
  .inline-block {
      display:inline-block;
  }
  
  .block {
      display:block;
  }
  
  .mt-8 {
      margin-top:8rem;
  }
  
  .mt-7 {
      margin-top:7rem;
  }
  
  .mt-6 {
      margin-top:6.25rem;
  }
  
  .text-sm {
      font-size:0.75rem;
  }
  
  .mute {
      opacity:0.75;
  }
  
  .va-sub {
      vertical-align: sub;
  }
  
  .va-mid {
      vertical-align: middle;
  }
  
  .va-top {
      vertical-align: top;
  }
  
  .va-base {
    vertical-align:baseline;
  }
  
  .va-bot {
    vertical-align: bottom;
  }
  
  .header {
    padding:28px 0px;
  }
  
  .header a {
    color: var(--main-text-color);
    font-style:normal;
  }
  
  .header a:hover {
    color:var(--main-color);
  }
  
  
  .dark-bg {
    background:var(--dark-bg);
  }
  
  .top-bar {
    backdrop-filter: blur(20px);
    background: #000000;
    border: 1px solid rgba(151,151,151,0.09);
    box-shadow: 0 2px 8px 1px rgba(0,0,0,0.50);
    padding:9px 0px;
    line-height:26px;
    font-size:14px;
    z-index:1005;
	height: 46px;
	position: fixed !important;
  }
  
  #top-bar-nav a {
    font-size:14px;
    font-weight:600;
    color:rgba(115,115,115,0.88);
  }
  #top-bar-nav a:hover {
    color:var(--main-color);
  }
  
  
  .top-bar .green {
    position: relative;
  }
  
  .top-bar .green:before{
	opacity: 0.25;
	background-image: radial-gradient(circle at 50% 150%, #B4E76F 0%, rgba(1,1,1,0.00) 50%);
	position: absolute;
	bottom: -12px;
	left: -60px;
	right: -60px;
	content: " ";
	height: 50px;
	z-index: -1;
  }
  
  .top-bar .orange {
    position: relative;
  }
  
  .top-bar .orange:before{
	opacity: 0.25;
	background-image: radial-gradient(circle at 50% 150%, #E7AD6F 0%, rgba(1,1,1,0.00) 50%);
	position: absolute;
	bottom: -12px;
	left: -60px;
	right: -60px;
	content: " ";
	height: 50px;
	z-index: -1;
  }
  
  
  #main-navigation {
    float:right;
    margin:10px 0px;
  }
  
  #main-navigation li {
    float:left;
    position: relative;
  }
  
  
  #main-navigation li.divider {
    background:url('../images/nav-divider.svg');
    width:18px;
    height:64px;
    content:" ";
    display:block;
    margin:-10px 0px;
    float:left;
  }
  
  #main-navigation li a {
    padding:4px 24px;
    font-size:14px;
    line-height:18px;
    color:#fff;
    font-weight:600;
    text-align:center;
    display:block;
    position: relative;
    text-transform: uppercase;
  }
  
  #main-navigation li a span {
    display:block;
    color:var(--main-color);
    font-size:12px;
  }
  
  #main-navigation li a:hover {
    color:var(--main-color);
    transform:scale(1.033);
  }
  
  #main-navigation li a:hover:before {
    position: absolute;
    top:-15px;
    left:-15px;
    right:-15px;
    bottom:-15px;
    background-image: radial-gradient(circle at 50% 50%, rgba(140,198,63,0.25) 0%, rgba(9,9,9,0.00) 30%);
    content:" ";
  }
  
  
  #main-navigation li a.button {
    border:1px solid #fff;
    line-height:44px;
    height:44px;
    padding:0px 24px;
    transform: skew(-15deg);
    background:transparent;
    margin-left:14px;
    box-shadow:unset;
    border-radius:5px;
  }
  
  #main-navigation li a.button strong {
    display:inline-block;
    transform: skew(15deg);
  }
  
  
  #main-navigation li a.button:hover {
    background:var(--main-color);
    border-color:var(--main-color);
  }
  
  
  
  
  #main-navigation .dropdown-menu {
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 64px !important;
    padding:6px;
      background: rgba(0,0,0,1);
    border:1px solid rgba(255,255,255,0.075);
    border-radius:8px;
    box-shadow: 0px 2px 12px rgb(0 0 0 / 50%);
    z-index:1010;
  }
  
  .fixed-header #main-navigation .dropdown-menu {
    border-top-left-radius:0px;
    border-top-right-radius:0px;
  }
  
  #main-navigation a.dropdown-toggle {
    margin:0px;
  }
  
  #main-navigation a.dropdown-toggle.show {
    color: var(--main-color);
    transform: scale(1.033);
  }
  
  #main-navigation a.dropdown-toggle.show:before {
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    background-image: radial-gradient(circle at 50% 50%, rgba(140,198,63,0.25) 0%, rgba(9,9,9,0.00) 30%);
    content: " ";
  }
  
  #main-navigation a.dropdown-toggle:after {
    display:none;
  }
  
  #main-navigation .dropdown-menu  {
    min-width:180px;
  }
  
  #main-navigation .dropdown-menu li  {
    display:block;
    float:none;
  }
  
  #main-navigation .dropdown-menu li a:hover {
    background:rgba(255,255,255,0.075);
    transform:unset;
  }
  
  #main-navigation .dropdown-menu li a{
    display:block;
    padding:12px 20px;
    border:1px solid rgba(255,255,255,0);
    border-radius:6px;
  }
  
  
  #main-navigation .dropdown-menu li:nth-last-child(1) {
    display:block;
  }
  
  #main-navigation .dropdown-menu li a:hover {
    background:rgba(255,255,255,0.075);
    border:1px solid rgba(255,255,255,0.075);
    transform:unset;
  }
  
  #main-navigation .dropdown-menu li a:hover:before {
    display:none;
  }
  
  .top-bar .dropdown-menu {
	min-width: 60px;
  }
  
  .dropdown-toggle i.fa-chevron-down {
    margin-bottom:1px;
  }
  
  .dropdown-toggle.show i.fa-chevron-down {
    transform:rotate(180deg);
  }
  
  .dropdown-menu-dark {
    background: rgba(0,0,0,1);
  border:1px solid rgba(255,255,255,0.075);
  box-shadow: 0px 2px 12px rgb(0 0 0 / 50%);
  }
  
  
  .dropdown-menu-dark  {
    padding:6px;
  }
  
  .dropdown-menu-dark .dropdown-item {
    padding:6px 20px;
    border-radius:6px;
  }
  
  .dropdown-menu-dark .dropdown-item:hover {
    cursor:pointer;
      background:rgba(255,255,255,0.075);
  }
  
  body.page-home {
    padding-top:0px;
    background:var(--body-bg);
  }
  
  .sub-hero {
    padding-top:118px;
  }
  
  .sub-hero h4 {
    font-family:"Titillium Web",sans-serif;
    font-weight:600 !important;
  }
  
  .hero-home {
    padding-top:0px !important;
  }
  
  .hero-home .carousel-item {
    padding-top:236px;
  }
  
  .sub-hero p{
    max-width:860px;
    margin:0px auto;
    font-size:22px;
    line-height:34px;
    font-weight:300;
    color:#EDEDED;
    padding-top:8px;
  }
  
  .game-search {
    border:1.5px solid var(--main-color);
    font-size:20px;
    color:#fff;
    background:url('../images/search.svg') center left 40px no-repeat rgba(0,0,0,0.5);
    width:100%;
    max-width:1020px;
    color:#fff;
    font-family:"Titillium Web",sans-serif;
    border-radius:16px;
    height:80px;
    padding:0px 40px 0px 80px;
    box-shadow: 0 0 8px 0 rgba(138,187,72,0.25);
  }
  
  
  .game-search:focus {
    border:1.5px solid var(--main-color-hover);
    box-shadow: 0 0 8px 0 var(--main-color);
    outline:none;
  }
  
  .game-search::placeholder {
    color:#fff;
  }
  
	.discordBox {
		max-width: 1100px;
		margin: 0 auto;
		padding: 0;
	}
  
  .pull-right{float:right;}
  .pull-left{float:left;}
  
  #hero .carousel-indicators {
      position: relative;
      right: unset;
      width:100%;
      bottom: unset;
      left: unset;
      z-index: 2;
      display:block;
      justify-content: center;
      padding: 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
  }
  
  
  .carousel-max-width {
    max-width:620px;
  }
  
  #hero .carousel-inner{
    padding-bottom:260px;
  }
  
  #hero .carousel-indicators {
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
  }
  
  #hero .carousel-indicators a {
    background:transparent;
  }
  
  #hero .carousel-indicators a img {
    border-radius:8px;
    margin:0px;
    width: 100%;
    position: relative;
    display: block;
    max-height:
  }
  
  
  #hero .carousel-indicators a:after {
    position: absolute;
    content:" ";
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    border:1px solid rgba(255,255,255,0.12);
    border-radius:8px;
  }
  
  #hero .carousel-indicators [data-bs-target] {
    border:0px;
    filter: grayscale(100%) brightness(50%);
	opacity: 1;
    display:block;
    height:100%;
    width:100%;
    display:block;
    position: relative;
    background:transparent;
    border-radius:8px;
    -webkit-box-shadow:0 0 8px 2px rgba(0,0,0,0.50);
	-moz-box-shadow: 0 0 8px 2px rgba(0,0,0,0.50);
	box-shadow: 0 0 8px 2px rgba(0,0,0,0.50);
	transition: filter 0.3s ease, transform 0.3s ease; /* Smooth filter and transform transition */
  }
  
  
  #hero .carousel-indicators [data-bs-target]:hover {
    filter: none; /* Removes grayscale */
  }
  
  #hero .carousel-indicators .active, #hero .carousel-indicators .active:hover {
    opacity:1;
    -webkit-box-shadow: 0 0 10px 1px rgba(138,187,72,0.28);
    -moz-box-shadow: 0 0 10px 1px rgba(138,187,72,0.28);
    box-shadow: 0 0 10px 1px rgba(138,187,72,0.28);
    transform:scale(1.05);
	filter: none; /* Removes grayscale */
  }
  
  
  #hero .carousel-indicators .active:after {
    border-color:rgba(138,187,72,0.35);
  }
  
  #hero .carousel-indicators .col {
    padding:0px 10px;
  }
  
  #hero .carousel-indicators .row {
    padding:0px 20px;
  }
  
  
  
  .hero-slider-right-content {
    position: absolute;
    bottom:206px;
    right:15px;
    text-align:right;
  }
  
  .hero-home .container {
      padding: 0px 15px !important;
  }

  .hero-nav {
    border: 1.5px solid rgba(255,255,255,0.2);
    width:44px;
    height:44px;
    line-height:42px;
    border-radius:50%;
    color:rgba(255,255,255,0.33);
    display:inline-block;
    text-align:center;
  }
  
  .hero-nav:hover {
    background-image: linear-gradient(213deg, rgba(255,255,255,0.00) 0%, rgba(138,187,72,0.32) 100%);
    border: 1.5px solid var(--main-color);
    color:#fff;
  }
  
  
  #home-intro-tabs li a, #home-intro-tabs .nav-item a  {
    padding:20px 28px;
    background-image: linear-gradient(269deg, rgba(10,10,10,0) 50%, rgba(60,60,60,0.35) 100%);
    border-radius: 89px;
    margin-top:25px;
    display:block;
      color:rgba(255,255,255,0.66);
  }
  
  #home-intro-tabs li a strong, #home-intro-tabs .nav-item a strong {
    color:rgba(255,255,255,0.66);
    margin-bottom:-3px;
    display:block;
    margin-top:2px;
  }
  
  #home-intro-tabs li a:hover, #home-intro-tabs .nav-item a:hover  {
    color:rgba(255,255,255,0.66);
    background-image: linear-gradient(269deg, rgba(10,10,10,0) 50%, rgba(60,60,60,0.5) 100%);
  }
  
  #home-intro-tabs li:nth-child(1) a, #home-intro-tabs .nav-item:nth-child(1){
    margin-top:0px;
  }
  
  #home-intro-tabs li .active, #home-intro-tabs li .active:hover, #home-intro-tabs .nav-item .nav-link.active, #home-intro-tabs .nav-item .nav-link.active:hover {
    color:rgba(255,255,255,0.75);
    background-image: linear-gradient(269deg, rgba(10,10,10,0) 50%, rgba(138,187,72,0.18) 100%);
  }
  
  #home-intro-tabs li .active i, #home-intro-tabs .nav-item .nav-link.active i {
    color:var(--main-color) !important;
  }
  
  #home-intro-tabs li .active strong, #home-intro-tabs .active strong, #home-intro-tabs .nav-item .active strong {
    color:#fff;
  }
  
  #home-intro-tabs li:after, #home-intro-tabs .nav-item:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
  }
  
  .block {
    display:block;
  }
  
  .font-44 {
    font-size:44px;
    line-height:54px;
  }
  
  .icon-text-tabs div {
    display:inline-block;
    position: relative;
    padding:0px 6px;
  }
  
  
  .icon-text-tabs div a{
    display:block;
    padding:20px 32px;
    color:#444444;
    font-size:14px;
    font-weight:600;
    text-transform: uppercase;
  }
  
  .icon-text-tabs div a .block {
    margin-top:5px;
  }
  
  .icon-text-tabs div a i, .icon-text-tabs div a span {
    transition: none;
  }
  
  .icon-text-tabs div a:hover, .icon-text-tabs div .active, .icon-text-tabs div .active:hover {
    color:var(--main-color)
  }
  
  .icon-text-tabs div .active:before {
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    background-image: radial-gradient(circle at 50% 40%, rgba(140,198,63,0.15) 0%, rgba(9,9,9,0.00) 40%);
    content: " ";
  }
  
  .responsive-img {
    max-width:100%;
  }
  
  .italic {
    font-style:oblique;
  }
  
  .lined-tabs li, .lined-tabs .nav-item {
    position: relative;
  }
  
  .lined-tabs li a, .lined-tabs .nav-item .nav-link {
    color:rgba(194,194,194,0.6);
    font-size:14px;
    text-transform: uppercase;
    padding:8px 20px;
    font-weight:600;
    display:block;
  }
  
  .lined-tabs li a:hover, .lined-tabs .nav-item .nav-link:hover {
    color:rgba(194,194,194,0.88);
  }
  
  .lined-tabs li a.active, .lined-tabs .nav-item .nav-link.active {
    color:#fff;
  }
  
  .lined-tabs li a.active:after, .lined-tabs .nav-item .nav-link.active:after {
    background-image: linear-gradient(90deg, rgba(0,0,0,0.0) 0%, var(--main-color) 50%, rgba(11,11,11,0.0) 100%);
    height:1px;
    position: absolute;
    content:" ";
    width:100%;
    left:0px;
    right:0px;
    bottom:0px;
  }
  
  .green-box {
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    text-align:center;
    padding:28px;
    background-image: linear-gradient(224deg, var(--main-color) 0%, #679d17 100%);
    border: 1px solid #BFF478;
    border-radius: 8px;
    color:rgba(255,255,255,1);
    width:22%;
  }
  
  
  .green-box:hover {
  background-image: linear-gradient(224deg, var(--main-color-hover) 0%, #8ABB48 100%);
  box-shadow: 0 0 12px 0 rgba(0,0,0,0.7);
  }
  
  .green-box a {
    color:#fff;
    font-weight:700;
  }
  
  .green-box a:hover {
    text-decoration: underline;
  }
  
  .box {
    background: #161616;
    border: 1.5px solid rgba(255,255,255,0.05);
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    border-radius: 8px;
    padding:28px;
  }
  
  .box:hover {
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.7);
    background: #181818;
    border: 1.5px solid rgba(255,255,255,0.07);
  }
  
  .slider-boxes {
    width:31.5%;
    margin-right:2.75%;
  }
  
  .review-box {
	height: 273px;
  }
  
  
.review-text {
	--max-lines: 5;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--max-lines);
	line-height: 1.5;
	max-height: calc(var(--max-lines) * 1.5em);
	margin-bottom: 10px;
}

.blur-background {
  position: relative;
}

.blur-background .overlay {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2999;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.blur-background.active .overlay {
  opacity: 1;
}

.enlarged {
  position: fixed;
  z-index: 3000;
  max-width: 90%;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s ease;
  border-radius: 20px;
}

.blur-background.active .enlarged {
  opacity: 1;
}

.slide-fade {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.responsive-img {
  transition: opacity 0.3s ease;
  cursor: pointer;
}
  
  #the-home-slider {
    padding:0px 60px;
    width:78%;
  }
  
  #the-home-slider .row {
    margin:0px;
  }
  
  .font-20 {
    font-size:20px;
    line-height:28px;
  }
  
  .relative {
    position: relative;
  }
  
  .abs-bottom {
    position: absolute;
    bottom:28px;
    left:0px;
    right:0px;
  }
  
  
  #home-features .main-col.col-lg-4 {
    width:30%;
    margin-right:5%;
    position: relative;
  }
  
  #home-features .main-col.col-lg-6 {
    width:47.5%;
    margin-right:5%;
    position: relative;
  }
  
  #home-features .main-col.has-divides:after,
  #home-features .main-col.has-divides:after,
  #home-features .main-col.has-divides:after {
    position: absolute;
    top:-30px;
    bottom:-30px;
    right:-25px;
    content:" ";
    width:1px;
  background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, #999999 30%, #999999 70%, rgba(0,0,0,0.00) 100%);
  transform:skew(-5deg);
  opacity:0.25;
  }
  
  
  #home-features .main-col.col-lg-4:nth-child(3), #home-features .main-col.col-lg-6:nth-last-child(1) {
    margin-right:0%;
  }
  
  #home-features .main-col {
    margin-bottom:60px;
  }
  
  #home-features .main-row {
    margin-bottom:-60px;
  }
  
  
  #home-features h3 {
    line-height:40px;
  }
  
  .gsl-logo {
    margin-bottom:3px;
  }
  
  #home-locations {
  
  background-image: radial-gradient(circle at 50% 58%, rgba(255,255,255,0.03) 0%, #0A0A0A 25%);
  }
  
  #home-community {
    background:rgba(23,23,23,0.5);
  }
  
  .green-bg {
    background:var(--main-color-dark);
  }
  
  .green-bg a {
    color:#fff;
    font-weight:600;
  }
  
  .green-bg a:hover {
    text-decoration: none;
  }
  
  
  .cta-link-list a {
  border-bottom:1px dashed transparent;
  }
  
  .cta-link-list a:hover {
    color:#fff;
    border-bottom:1px dashed #fff;
  }
  
  .cta-underline {
    position: relative;
    font-weight:700;
    display:inline-block;
  }
  
  .cta-underline:before {
    position: absolute;
    bottom:-12px;
    left:-3px;
    right:0px;
    background:url('../images/cta-underline.svg');
    width:100px;
    height:18px;
    content:" ";
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .footer {
    padding:88px 0 0 0;
  }
  
  #footer-links a {
    color:#C2C2C2;
    display:block;
  }
  
  #footer-links a:hover {
    color:var(--main-color);
  }
  
  #footer-links li, #footer-links h5 {
    padding:6px 0px;
  }
  
  #footer-links h5 {
    font-size:14px;
    text-transform: uppercase;
  }
  
  
  #copyright .col.ml-auto {
    opacity:0.66;
  }
  
  .text-right{text-align:right !important;}
  .text-left{text-align:left !important;}
  
  .footer-secondary {
    border-top:1px solid rgba(255,255,255,0.12);
    margin-top:88px;
    padding:28px 0px;
  }
  
  #copyright {
    background:#171717;
      color:rgba(194,194,194,0.66);
  }
  
  #copyright a {
    color:rgba(194,194,194,0.66);
  }
  
  #copyright a:hover {
    color:rgba(194,194,194,1);
  }
  
  .tp-logo {
    margin-top: -6px;
  }
  
  .social-list a {
    color:#fff;
    font-weight:600;
  }
  
  .social-list a:hover {
    color:var(--main-color);
  }
  
  .social-list a i {
    width:24px;
    text-align:center;
    margin-right:6px;
  }
  
  .green-icon {
    color:var(--main-color);
  }
  
  .white-link {
    color:#fff;
  }
  
  .white-link:hover {
    color:var(--main-color);
  }
  
  #games-tabs .lined-tabs a {
    font-size:22px;
    text-transform: capitalize;
    padding: 16px 28px;
  }
  
  .game-box-large {
	background-position: center center;
	background-repeat: no-repeat;
    background-size:104% !important;
    width:100%;
    position: relative;
    min-height:420px;
    border-radius:16px;
    border: 1px solid rgba(151,151,151,0.10);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.25);
    display:block;
    color:#fff;
  }
  
  .game-box {
    background-size:cover !important;
  }
  
  .game-box-large:hover, .game-box:hover {
    transform:scale(1.025);
    color:#fff;
	border: 1px solid rgba(138,187,72,0.35);
	box-shadow: 0 0 10px 1px rgba(138,187,72,0.28);
  }
  
  .game-box-bottom {
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: 1001;
	margin-left: -1px;
	margin-right: -1px;
	padding-top: 10px;
	padding-bottom: 10px;
	bottom: -2px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border: 1px solid rgba(255,255,255,0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	background: linear-gradient(338deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  }
  
  .game-box-large:hover .game-box-bottom, .game-box:hover .game-box-bottom {
	bottom:-1px;
  }
  
  .game-box-bottom span.font-14 {
    font-weight:700;
    font-size:14px;
    padding:5px 0px;
    display:block;
    margin-top:6px;
    color:#fff;
  }
  
  .game-box {
    width:100%;
    position: relative;
    min-height:335px;
    border-radius:10px;
    color:#fff;
    border:1px solid rgba(255,255,255,0.1);
    background-size:104% !important;
    display:block;
    color:rgba(255,255,255,1);
  }
  
  .game-box:hover {
  color:rgba(255,255,255,1);
  }
  
  .game-box:before {
    z-index:1;
    content:" ";
    position: absolute;
    bottom:-1px;
    left:-1px;
    right:-1px;
    top:26.6666%;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.70) 100%);
    display:block;
  }
  
  
  .game-box:hover:before {
  background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.85) 100%);
  }
  
  .game-name {
	font-size: 16px;  
	font-weight:500;
  }
  
  .game-box .game-box-bottom {
	margin-left: -1px;
	margin-right: -1px;
	padding-top: 10px;
	padding-bottom: 10px;
	bottom: -2px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border: 1px solid rgba(255,255,255,0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	background: linear-gradient(338deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  }
  
  .game-box:hover .game-box-bottom {
    bottom:-1px;
  }
  
  .learn-more {
	display: none;
  }
  
  .game-box:hover .learn-more {
	display: unset;
  }
  
  .game-box-large:hover .learn-more {
	display: unset;
  }
  
  .game-box .game-box-bottom h3 {
    line-height:26px;
  }
  
  .game-box .game-box-bottom h4 {
    line-height:24px;
  }
  
  .game-box .game-box-bottom a {
    color:#fff;
  }
  
  .col-lg-25 {
    flex: 0 0 auto;
    width: 20%;
  }
  
  .games-input {
    height:56px;
    padding:0px 20px 0px 52px;
    font-size:16px;
    border:1px solid rgba(151,151,151,0.42);
    width:100%;
    border-radius:56px;
    color:#fff;
    background:url('../images/search-small.svg') center left 24px no-repeat transparent;
  }
  
  .games-input::placeholder {
    color:rgba(185,185,185,0.66);
  }
  
  .games-input:focus {
    border:1px solid rgba(151,151,151,0.66);
    background:url('../images/search-small-focus.svg') center left 24px no-repeat transparent;
    outline:0;
  }
  
  .w-66 {
    width:66.666%;
  }
  
  
  .input-group {
    display:block;
    width:100%;
    border:1px solid rgba(151,151,151,0.42);
    height:56px;
    border-radius:56px;
  }
  
  .input-group-left {
    width:25%;
    float:left;
    border-right:1px solid rgba(151,151,151,0.42);
    padding:0px 0px 0px 20px;
    line-height:54px;
    text-align:left;
    background:rgba(216,216,216,0.06);
    border-radius:56px 0px 0px 56px;
  }
  
  .input-group-right {
    width:75%;
    float:left;
    height:54px;
    line-height:54px;
    padding:0px 24px;
    display:block;
    background:url('../images/select-down.svg') center right 20px no-repeat transparent;
    color:rgba(185,185,185,0.66);
    font-family:"Titillium Web",sans-serif;
    border:0px;
    -webkit-appearance:none;
    appearance:none;
  }
  
  
  #games-trustpilot {
    line-height:28px;
    font-size:18px;
    color:#fff;
  }
  
  #games-trustpilot h3 {
    line-height:28px;
  }
  
  #games-trustpilot strong {
    font-weight:600;
  }
  
  #games-trustpilot .tp-stars {
    height:28px;
    margin-bottom:2px;
  }
  
  
  #games-rows {
  margin-bottom:-20px;
  padding:0px 5px;
  }
  
  #games-rows .col{
    margin:0px -1px;
  margin-bottom:20px;
  padding:0px 10px;
  }
  
  .game-header-name {
	white-space: nowrap;
  }
  
  #game-details-list li {
    display:inline-block;
    padding:10px 40px;
    position: relative;
  }
  
  
  #game-details-list li h2 {
    line-height:32px;
    padding-top:4px;
  }
  
  #game-details-list li:after {
    position: absolute;
      top:0px;
      bottom:0px;
      right: 0px;
      content: " ";
      width: 1px;
      background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, var(--main-color) 40%, var(--main-color) 60%, rgba(0,0,0,0.00) 100%);
      transform: skew(-7.5deg);
      opacity: 0.7;
  }
  
  
  #game-details-list li:nth-child(1):before {
    position: absolute;
      top:0px;
      bottom:0px;
      left: 0px;
      content: " ";
      width: 1px;
      background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 0%, var(--main-color) 40%, var(--main-color) 60%, rgba(0,0,0,0.00) 100%);
      transform: skew(-7.5deg);
      opacity: 0.7;
  }
  
  .store-windows {
	vertical-align: top;
  }
  
  .store-steam {
	font-size: 20px;
	vertical-align: sub;
  }
  
  .store-steam:hover {
	color: var(--main-color);
  }
  
  .locations-link {
    position: relative;
  }
  
  .locations-link:after {
    position: absolute;
    content: "\f078";
    font-family:"Font Awesome 6 Pro";
    left:0px;
    right:0px;
    text-align:center;
    color: #000;
    bottom: -13px;
    color:var(--main-color);
    z-index: 1;
  }
  
  .green-bar-nav {
    background: var(--main-color-dark);
    color:#fff;
  }
  
  .green-bar-nav .container {
    padding-top:12px;
    padding-bottom:12px;
  }
  
  #gameserver-nav {
    float:left;
  }
  
  #gameserver-nav li {
    display:inline-block;
    padding:6px 0px;
  }
  
  #gameserver-nav li a {
    display:block;
    padding:0px 16px;
    font-size:16px;
    font-weight:600;
    height:36px;
    line-height:34px;
    border:1px solid transparent;
    color:rgba(255,255,255,0.75);
    border-radius: 18px;
  }
  
  #gameserver-nav li a:hover {
  color:rgba(255,255,255,1);
  }
  
  #gameserver-nav a.active {
    background: rgba(0,0,0,0.16);
    border:1px solid #549100;
    box-shadow: inset 0 3px 3px 0 rgba(0,0,0,0.08);
    border-radius: 18px;
    text-shadow: 0px -1px rgb(0 0 0 / 10%);
    color:#fff;
  }
  
  #gameserver-nav li a.font-16 {
    font-size:16px;
    padding:0px 24px;
  }
  
  
  #gameserver-nav-order  {
    float:right;
  }
  
  #gameserver-nav-order li {
    display:inline-block;
    height:48px;
    float:left;
  }
  
  .absolute-left-parent {
    position: relative;
    padding-left:96px;
  }
  
  .absolute-left {
    position: absolute;
    top:0px;
    left:0px;
  }
  
  .features .col {
    margin-bottom:50px;
  }
  .features .col .col {
    margin-bottom:0px;
  }
  
  .features .row {
    margin-bottom:-50px;
  }
  
  .features .col .row {
    margin-bottom:0px;
  }
  
  .light-bg {
    background:rgba(23,23,23,0.5);
  }
  
  .gsl-mark-sm {
    height:25px;
    vertical-align: text-bottom;
    margin-bottom: 1px;
  }
  
  .panel-tab-box {
    border-left:2px solid rgba(151,151,151,0.4);
    padding:14px 24px 14px 40px;
    position: relative;
  }
  
  .panel-tab-box p {
    padding-bottom:6px;
  }
  
  .panel-tab-box:hover {
    border-left:2px solid rgba(151,151,151,0.75);
  }
  
  .panel-tab-box h5 {
    line-height:34px;
    padding-bottom:4px;
  }
  
  .panel-tab-box p {
    display:none;
  }
  
  .panel-tab-box.active {
    border-color:var(--main-color) !important;
    background:#121212;
  }
  
  
  .panel-tab-box.active:after {
    position: absolute;
    content:" ";
    width:10px;
    height:18px;
    background:url('../images/inner-panel-active.svg');
    top:50%;
    transform:translateY(-50%);
    left:-8px;
  }
  
  .panel-tab-box.active h5, .panel-tab-box.active h5 i {
    color:var(--main-color);
    transition: unset;
  }
  
  .panel-tab-box.active p {
    display:block;
  }
  
  h5 .font-24 {
    line-height:24px;
  }
  
  .cta-gradient-box {
    position: relative;
    background:url(../images/bg-lines.svg) top right no-repeat #2A2A2A;
    box-shadow: 4px 14px 26px 0 rgba(0,0,0,0.10);
    border-radius: 18px;
    padding:80px 88px;
  }
  
  .cta-gradient-box:before {
    border-radius:18px 0px 0px 18px;
    content: " ";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width:70%;
  background-image: linear-gradient(90deg, #121212 0%, rgba(42,42,42,0.00) 93%);
    z-index: 1;
  }
  
  
  .cta-gradient-box:after {
    position: absolute;
    z-index:1;
    content:" ";
    left:0;
    right:0;
    top:0;
    bottom:0;
    border-radius:18px;
    border:1px solid rgba(255,255,255,0.05);
  }
  
  
  .cta-gradient-box .row {
    position: relative;
    z-index:1001;
  }
  
  .line-gradient-divider {
    display: block;
    width: 100%;
    height: 1px;
    opacity: 0.4;
    background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, #676767 50%, rgba(0,0,0,0.00) 100%);
  }
  
  .order-with-line {
    position: relative;
  }
  
  .order-with-line:before {
    top:50%;
    left:0px;
    right:0px;
    content:" ";
    position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.6;
      background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, var(--main-color) 50%, rgba(0,0,0,0.00) 100%);
    z-index:-1;
  }
  
  .green-line {
    position: relative;
  }
  
  .green-line:before {
    top:50%;
    left:50%;
	transform: translate(-50%, -50%);
    content:" ";
    position: absolute;
      display: block;
      width: 120px;
      height: 1px;
      opacity: 0.6;
      background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, var(--main-color) 50%, rgba(0,0,0,0.00) 100%);
  }
  
  #dedicated-trustpilot .tp-stars {
    height:24px;
  }
  
  #dedicated-trustpilot a {
    border-bottom:1px dashed #fff;
    color:#fff;
    font-weight:600;
  }
  
  .server-flag {
	width: 60px;
	border-radius: 8px;
  }
  
  .col-server-details {
      flex: 0 0 auto;
      width: 83%;
  }
  
  .col-server-order {
      flex: 0 0 auto;
      width: 20%;
  }
  
  .col-cpu {
      flex: 0 0 auto;
      width: 35%;
  }
  
  .col-ram {
      flex: 0 0 auto;
      width: 25%;
  }
  
  .col-ssd {
      flex: 0 0 auto;
      width: 15%;
  }
  
  .col-transfer {
      flex: 0 0 auto;
      width: 20%;
  }
  
  .col-server-details .font-12 {
    font-weight:700;
    line-height:20px;
  }
  
  .server-cpu-make {
	width: 35px;
  }
  
  
  .plan-labels-list {
    margin-top:5px;
  }
  
  .plan-labels-list li {
    border:1.5px solid rgba(151,151,151,0.3);
    font-size:14px;
    color:#6C6C6C;
    height:32px;
    line-height:30px;
    padding:0px 14px;
    border-radius:32px;
    display:inline-block;
    margin-right:10px;
    font-weight:600;
  }
  .plan-labels-list li:hover {
    border:1.5px solid rgba(151,151,151,0.6);
    color:#848484
  }
  
  .plan-labels-list li i {
    margin-bottom: 3px;
  }
  
  .server-on-sale {
    border-color:var(--main-color) !important;
    color:var(--main-color) !important;
  }
  
  .server-new {
    border-color:#46a8ff !important;
    color:#46a8ff !important;
  }
  
  .server-info {
	border-color: var(--secondary-color) !important;
	color: var(--secondary-color) !important;
  }
  
  .server-stock {
	border-color: #ddd !important;
	color: #ddd !important;
  }
  
  .server-stock-low {
	border-color: #f24a4a !important;
	color: #f24a4a !important;
  }
  
  .server-sale-price-old {
	font-size: 16px;
	padding-right: 8px;
	text-decoration: line-through;
	opacity: 0.5;
	color: #fff;
	line-height: normal;
  }
  
  .server-box:hover {
	border: 1px solid rgba(138,187,72,0.35);
	box-shadow: 0 0 10px 1px rgba(138,187,72,0.28);
  }
  
  .server-box {
    margin-top:30px;
  }
  
  #dedicated-os .col {
    padding:0px 10px;
  }
  
  #dedicated-os .row {
    padding:0px 5px;
  }
  
  #dedicated-os .box {
    padding:40px 20px;
  }
  
  #dedicated-os .box img:nth-child(2) {
    display:none;
  }
  
  #dedicated-os .box:hover img:nth-child(1) {
    display:none;
  }
  
  #dedicated-os .box:hover img:nth-child(2) {
    display:block;
  }
  
  #cloud-features .custom-logo img:nth-child(2) {
    display:none;
  }
  
  #cloud-features .custom-logo:hover img:nth-child(1) {
    display:none;
  }
  
  #cloud-features .custom-logo:hover img:nth-child(2) {
    display:block;
  }
  
  .location-feature strong {
    line-height:24px !important;
    margin-top:3px;
  }
  
  #testimonial-slider p {
    color:#fff;
    max-width:740px;
    margin:0px auto 40px auto;
  }
  
  #testimonial-slider {
    padding-top:168px;
    margin-top:-80px;
    padding-bottom:72px;
  }
  
  #testimonial-slider .white-link img {
    margin-bottom:5px;
  }
  
  #testimonial-slider .white-link {
  border-bottom:1px dashed transparent;
  }
  
  #testimonial-slider .white-link:hover {
    color:#fff;
    border-bottom:1px dashed #fff;
  }
  
  .cloud-box h3 img {
    margin-bottom:1px;
  }
  
  .col-cloud-price h3 {
    line-height:54px;
    height:50px;
  }
  
  .col-cloud-order {
    padding-top:1px;
  }
  
  
  
  
  .col-cloud-cpu {
      flex: 0 0 auto;
      width: 17%;
  }
  
  .col-cloud-ram {
      flex: 0 0 auto;
      width: 20%;
  }
  
  .col-cloud-ssd {
      flex: 0 0 auto;
      width: 14%;
  }
  
  .col-cloud-os {
      flex: 0 0 auto;
      width: 19%;
  }
  
  .col-cloud-panel {
      flex: 0 0 auto;
      width: 15%;
  }
  
  .col-cloud-price {
      flex: 0 0 auto;
      width: 16%;
  }
  
  .col-cloud-order {
      flex: 0 0 auto;
      width: 16%;
  }
  
  .sub-hero p.font-20 {
    font-size:20px;
    line-height:32px;
  }
  
  #cloud-hero-list {
    color:#fff;
  }
  #cloud-hero-list li {
    line-height:44px;
  }
  
  .cloud-hero-icon {
    background: rgba(0,0,0,0.5);
    box-shadow: inset 0 3px 3px 0 rgba(0,0,0,0.50);
    border-radius: 50%;
    width:44px;
    height:44px;
    text-align:center;
    line-height:44px;
    float:left;
    margin-right:18px;
    color:var(--main-color);
  }
  
  .swap-games-search  {
    display:block;
    width:100%;
    text-align:center;
    padding-top:44px;
  }
  
  
  .swap-games-search .game-search {
    max-width:760px;
    margin:0px auto !important;
    height:68px;
    background-size:18px;
    padding: 0px 32px 0px 76px;
  
  }
  
  #hero-about p {
    max-width:860px !important;
    font-size:20px;
    line-height:32px;
  }
  
  #hero-about h3 {
    font-weight:500;
  }
  
  .box-outline {
    border:1px solid rgba(151,151,151,0.33);
    padding:48px 24px 40px 24px;
    text-align:center;
    border-radius:8px;
	height: 300px;
  }
  
  .padded-row {
    padding:0px 68px;
  }
  
  #about-statistics .row {
    padding:0px 5px;
  }
  
  #about-statistics .col {
    padding:0px 10px;
  }
  
  .col-lg-25 {
      flex: 0 0 auto;
      width: 20%;
  }
  
  .stat-box {
    background:var(--main-color);
    border-radius:8px;
    padding:40px 24px;
    min-height:312px;
    position: relative;
  }
  
  
  .stat-box i {
    color:#fff;
    position: absolute;
    bottom:24px;
    left:24px;
  }
  
  .stat-box:hover {
    transform:scale(1.025);
  }
  
  .stat-one {
    background:url('../images/bg-stat-1.svg') center bottom no-repeat var(--main-color-dark);
  }
  
  .stat-one:hover {
    background:url('../images/bg-stat-1.svg') center bottom no-repeat var(--main-color-dark-hover);
  }
  
  .stat-two {
    background:url('../images/bg-stat-2.svg') center bottom no-repeat var(--main-color-dark);
  }
  
  .stat-two:hover {
    background:url('../images/bg-stat-2.svg') center bottom no-repeat var(--main-color-dark-hover);
  }
  
  .stat-three {
    background:url('../images/bg-stat-3.svg') center bottom no-repeat #3E3E3E;
  }
  
  .stat-three:hover {
    background:url('../images/bg-stat-3.svg') center bottom no-repeat #464646;
  }
  
  .stat-four {
    background:url('../images/bg-stat-4.svg') center bottom no-repeat #2B2B2B;
  }
  
  .stat-four:hover {
    background:url('../images/bg-stat-4.svg') center bottom no-repeat #333333;
  }
  
  .stat-five {
    background:url('../images/bg-stat-5.svg') bottom right no-repeat #1D1D1D;
  }
  .stat-five:hover {
    background:url('../images/bg-stat-5.svg') bottom right no-repeat #212121;
  }
  
  
  .quick-links li {
    border-top:1px solid rgba(151,151,151,0.33);
  }
  
  .quick-links li:nth-last-child(1) {
    border-bottom:1px solid rgba(151,151,151,0.33);
  }
  
  .quick-links li a {
    line-height:46px;
    height:92px;
    font-size:20px;
    color:#fff;
    font-family:"Din Next LT Pro",sans-serif;
    display:block;
    padding:24px 26px 24px 20px;
  }
  
  .quick-links li .font-44, .quick-links li .font-18 {
    line-height:44px !important;
  }
  
  .quick-links li .fa-chevron-right {
    color:#fff;
  }
  
  .quick-links li a:hover, .quick-links li a:hover .fa-chevron-right {
    color:var(--main-color);
  }
  
  .quick-links li a:hover {
    padding-right:20px;
  }
  
  .button.large .fa-arrow-right-long {
    vertical-align: middle;
    margin-bottom: 2px;
  }
  
  .aff-step {
    float:left;
    margin-right:12px;
    font-size:14px;
    color:var(--main-color);
    border:1.5px dashed var(--main-color);
    line-height:28px;
    height:28px;
    border-radius:28px;
    padding:0px 16px;
    text-transform: uppercase;
  }
  
  .aff-box {
    background: #121212;
    border:1px solid rgba(255,255,255,0.03);
  box-shadow: 4px 4px 12px 1px rgba(0,0,0,0.36);
  border-radius: 12px;
  padding:48px 20px 56px 20px;
  text-align:center;
  position: relative;
  }
  
  .aff-box:hover {
    background: #141414;
    border:1px solid rgba(255,255,255,0.04);
    box-shadow: 4px 4px 12px 1px rgba(0,0,0,0.66);
  }
  
  .aff-tooltip {
    position: absolute;
    bottom:-16px;
    left:50%;
    transform:translateX(-50%);
    margin-right:-2px;
  }
  
  .aff-tooltip img {
    height:32px;
    margin-right:-2px;
  }
  
  .aff-textarea {
	border: 1px solid rgb(32, 32, 32);
	background-color: rgb(10, 10, 10);
	color: rgb(255, 255, 255);
	height: 135px;
	padding: 0px;
	margin: 0px;
  }
  
  .home-intro-tab-bg {
    background:url('../images/home-intro-bg.svg') no-repeat;
    position: absolute;
    content:" ";
    left: -70px;
    bottom: -104px;
    width:524px;
    height:438px;
    z-index:-1;
  }
  
  .tab-line-start {
    width: 26.2%;
    height:1px;
    content:" ";
    position: absolute;
    top: 20px;
    left: 144px;
    background-image: linear-gradient(270deg, var(--main-color) 0%, rgba(0,0,0,0.00) 80%);
  }
  
  .icon-text-tabs{
    position: relative;
  }

  .icon-text-tabs:before {
    width: 100%;
    height:1px;
    content:" ";
    position: absolute;
    top: -4px;
    left: 0;
    background-image: linear-gradient(90deg, var(--main-color) 80%, rgba(0,0,0,0.00) 100%);
  }
  
  
  .icon-text-tabs .active {
    position: relative;
  }
  
  .icon-text-tabs .active:after {
    width: 100%;
    height:12px;
    content:" ";
    position: absolute;
    top: -5px;
    left: 15px;
    background: url('../images/panel-tab-active.svg') center top no-repeat;
  }
  
  .map {
    background:url('../images/world-map.svg') center center no-repeat;
    width:960px;
    height:454px;
    margin:0px auto;
    position: relative;
    z-index:1001;
  }
  
  .pin {
      background: url(../images/map-pin.svg) center center no-repeat;
      width:24px;
      height:24px;
      background-size:33%;
      position: absolute;
  }
  
  .pin:hover, .pin.active {
      margin-top: -2px;
      width:24px;
      height:24px;
      background-size:66.666%;
      cursor:pointer;
  }
  
  .pin-hover {
      white-space: nowrap;
      position: absolute;
      top: -116px;
      left: 50%;
      border-radius: 6px;
      padding: 12px 18px;
      font-weight: 500;
      transform: translateX(-50%);
      margin-bottom: 26px;
      z-index: 1001;
      width:180px;
      text-align:left;
      color:#BDBDBD;
      background: #262626;
  box-shadow: 0 1px 8px 2px rgba(0,0,0,0.44);
  margin-top:-60px;
  margin-left:12px;
  display: none;
  }
  
  .pin-hover:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      width: 0;
      height: 0;
      margin-bottom:-2px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #000;
      clear: both;
  }
  .pin-hover:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #262626;
      clear: both;
  }
  
	.pin.active + .pin-hover {
		display: block;
	}
  
  .orange-link {
    color:var(--secondary-color);
  }
  
  .orange-link:hover {
    color:var(--secondary-color-hover);
  }
  
  .font-6 {
    font-size:6px;
    margin-bottom:3px;
  }
  
  
  
  .location-sea, .location-sea-info {
    top: 30.6%;
    left: 9.9%;
  }
  
  .location-la, .location-la-info {
    top: 42.90%;
    left: 12.4%;
  }
  
  .location-dal, .location-dal-info {
    top: 44.75%;
	left: 17.8%;;
  }
  
  .location-chi, .location-chi-info {
	top: 35.8%;
	left: 20.23%;
  }
  
  .location-atl, .location-atl-info {
    top: 42.9%;
    left: 21.25%;
  }
  
  .location-fl, .location-fl-info {
    top: 48.4%;
    left: 22.7%;
  }
  
  .location-ny, .location-ny-info {
    top: 35.8%;
    left: 25.15%;
  }
  
  .location-qc, .location-qc-info {
    top: 28.7%;
    left: 26.125%;
  }
  
  .location-br, .location-br-info {
    top: 75%;
    left: 32.125%;
  }
  
  .location-uk, .location-uk-info {
    top: 28.7%;
    left: 48.2%;
  }
  
  .location-fr, .location-fr-info {
    top: 34%;
    left: 49.6%;
  }
  
  .location-ne, .location-ne-info {
    top: 28.7%;
    left: 50.15%;
  }
  
  .location-gr, .location-gr-info {
    top: 32.25%;
    left: 51.15%;
  }

  .location-pl, .location-pl-info {
    top: 30.5%;
    left: 53.6%;
  }
  
  .location-it, .location-it-info {
	left: 51.15%;
	top: 36%;
  }
  
  .location-sg, .location-sg-info {
    top: 60.75%;
    left: 76.7%;
  }
  
  .location-nz, .location-nz-info {
	top: 83.7%;
	left: 92.8%;
  }
  
  .location-mel, .location-mel-info {
    top: 83.95%;
    left: 87%;
  }
  
  .location-adl, .location-adl-info {
    top: 82.2%;
    left: 84.55%;
  }
  
  .location-syd, .location-syd-info {
    top: 82.25%;
    left: 88.5%;
  }
  
  .location-bris, .location-bris-info {
    top: 76.9%;
    left: 89%;
  }
  
  .location-perth, .location-perth-info {
    top: 80.33%;
    left: 79.15%;
  }
  
  .location-jp, .location-jp-info {
    top: 42.9%;
    left: 85.6%;
  }
  
  .location-hk, .location-hk-info {
	top: 49.6%;
	left: 77.55%;
  }
  
  .location-pr, .location-pr-info {
	top: 32.4%;
	left: 49.2%;
  }
  
  .location-mrs, .location-mrs-info {
	top: 35.8%;
	left: 49.2%;
  }
  
  .location-ash, .location-ash-info {
	top: 37.6%;
	left: 23.75%;
  }
  
  .location-mia, .location-mia-info {
	top: 48.5%;
	left: 22.75%;
  }
  
  .map-has-list .map {
    width: 829px;
    background-size: 100%;
    height: 391px;
  }
  
  
  .map-has-list ul li {
    cursor:pointer;
  }
  
  .map-has-list ul li.active {
    color:var(--main-color);
    font-weight:700;
  }
  
  #testimonials-slider-boxes .carousel-control-prev, #testimonials-slider-boxes  .carousel-control-next {
    width:5%;
    opacity:0.33;
    width:60px;
    cursor:pointer;
  }
  
  #testimonials-slider-boxes .carousel-control-prev:hover, #testimonials-slider-boxes  .carousel-control-next:hover {
    opacity:1;
  }
  
  #testimonials-slider-boxes .carousel-control-prev {
    left:-60px;
  }
  
  #testimonials-slider-boxes  .carousel-control-next {
    right:-60px;
  }
  
  #home-features a:hover, #home-features a, #home-features a span, #home-features a:hover span, #home-features a:hover i, #home-features a i {
    transition: unset !important;
  }
  
  .big-timer {
    font-size:40px;
    line-height:48px;
    padding-bottom:32px;
    font-family:"Din Next LT Pro",sans-serif;
  }
  
  .big-timer span {
    padding:0px 6px 32px 6px;
    position: relative;
  }
  
  
  .big-timer span:after {
    font-size:14px;
    position: absolute;
    bottom:0px;
    left:0px;
    right:0px;
    font-family:"Titillium Web",sans-serif;
  }
  
  .big-timer span:nth-child(1):after {content:"DAYS";}
  .big-timer span:nth-child(2):after {content:"HOURS";}
  .big-timer span:nth-child(3):after {content:"MINS";}
  .big-timer span:nth-child(4):after {content:"SECS";}
  
  .bg-cta-game-list {
    background:url('../images/bg-cta-game-list.webp') no-repeat var(--main-color-dark);
	background-position: calc(50% - -300px) 50%;
  }
  .bg-cta-squares {
    background:url('../images/bg-cta-squares.webp') center center no-repeat var(--main-color);
    background-size:cover;
  }
  
  .label {
    height:24px;
    line-height:24px;
    background:var(--main-color-dark);
    color:#fff;
    padding:0px 10px;
    font-size:12px;
    border-radius:24px;
    display:inline-block;
    font-weight:600;
  }
  
  .label.secondary {
    background:var(--secondary-color);
  }
  
  .loc-list:hover {
    font-weight:600;
    color:#fff;
  }
  
  .extra-keywords {
    display:none;
  }
  
  .game-container {
    padding:0px;
  }
  
  .hidebigsearch, #games-all-games {
    width:100%;
    transition:unset;
    animation-delay:3s;
  }
  
  .filterselect-main {
    background:url('../images/select-down.svg') center right 28px no-repeat transparent;
    font-family:"Titillium Web",sans-serif;
    font-size:20px;
    border:0px;
    padding:0px 40px 0px 28px;
    min-width:25%;
    border-left:1.5px solid rgba(138,187,72,0.75);
    color:#fff;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    border-radius:0px 80px 80px 0px;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom:0px;
  }

  .filterselect-main:focus {
    outline:none;
    background:url('../images/select-down.svg') center right 28px no-repeat rgba(255,255,255,0.03);
  }
  
  .filterselect-main option {
	background-color: #222;
  }
  
  .filterselect option {
	background-color: #222;
  }
  
  .game-search-container {
    width:100%;
    max-width:1020px;
  }
  
  .padded-row-big {
    padding:0px 120px;
  }
  
  .top-bar {
    position: absolute;
    left: 0;
    right: 0;
    top:0px;
    transition: none;
  }
  
  .header {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    z-index:1001;
    transition: none;
  }
  
  #main-navigation {
  
    transition: none;
  }
  
  .fixed-header {
    top:46px;
    transition: none;
    position: fixed;
    background:rgba(0,0,0,1);
    box-shadow:0 2px 6px 0 rgb(0 0 0 / 30%);
    padding:18px 0px;
    border-bottom:1px solid rgba(255,255,255,0.075);
    z-index: 1010;
    transition: none;
  }
  
  .fixed-top-bar {
	 border-bottom: none;
	 z-index: 1011;
  }
  
  .fixed-top-bar .green:before {
	 display:none;
  }
  
  .fixed-header .logo {
    height:48px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  
  .fixed-header #main-navigation {
    margin:2px 0px;
  }
  
  body {
    padding-top:120px;
  }
  
  .stick {
    position: fixed;
    top: 131px;
    right:0px;
    left:0px;
    z-index: 1004;
  }
  
  #features, #greenBarAnchor {
    transition:none;
  }
  
  .faq-item {
    border:1px solid rgba(255,255,255,0.2);
    margin-bottom:20px;
    border-radius:8px;
  }
  
  .accordion-item:first-of-type {
  border-radius:8px !important;
  }
  
  .accordion-item:not(:first-of-type) {
    border-top:1px solid rgba(255,255,255,0.2);
  }
  
  .accordion-button {
    background:url('../images/qmark.svg') left 24px top 18px no-repeat;
    height:36px !important;
    line-height:1 !important;
    padding-left:36px;
  }
  
  .accordion-header {
      padding-bottom: 0px;
  }
  
  .accordion-body {
    border-top:1px solid rgba(255,255,255,0.2);
    padding:20px 24px 20px 24px;
  
  }
  
  .accordion-button {
  
    padding:28px 24px 28px 58px;
  }
  
  .accordion-button::after {
    background-image:unset;
    transform:unset;
    transition: unset;
    background:url('../images/select-down.svg') center center no-repeat;
    width:16px;
    height:16px;
    content:" ";
    position: absolute;
    top:50%;
    margin-top:-8px;
    right:24px;
    bottom:0px;
  }
  
  .accordion-button:not(.collapsed):after {
  background:url('../images/select-down.svg') center center no-repeat;
  position: absolute;
  top:50%;
  margin-top:-8px;
  right:24px;
  bottom:0px;
  transform:rotate(180deg);
  }
  
  .accordion-item:last-of-type {
    border-radius:8px !important;
  }
  
  
  #testimonial-slider .carousel-control-prev, #testimonial-slider .carousel-control-next {
  bottom:62%;
  width:10%;
  }
  
  
  #testimonial-slider .carousel-control-prev {
    left:5%;
  }
  
  #testimonial-slider .carousel-control-next {
    right:5%;
  }
  
  .hiw-step {
    position: relative;
  }
  
  .aff-underline:before {
    width:100%;
    background-size:110%;
    background-repeat: no-repeat;
  }
  
  .hiw-step-1 {
    background:url('../images/step-1-line.svg');
    width:11px;
    height:105px;
    position: absolute;
    left:24px;
    top:66px;
  }
  
  .hiw-step-2 {
    background:url('../images/step-2-line.svg');
    width:11px;
    height:68px;
    position: absolute;
    left:24px;
    top:64px;
  }
  
  
  .hiw-step-3 {
    background:url('../images/step-3-line.svg');
    width:359px;
    height:151px;
    position: absolute;
    left:4px;
    bottom:-92px;
  }
  
  
  
  /**
   * Tooltip Styles
   */
  
  [data-tooltip],
  .tooltip {
      position: relative;
      cursor: pointer;
  }
  
  /* Base styles for the entire tooltip */
  [data-tooltip]:before,
  [data-tooltip]:after,
  .tooltip:before,
  .tooltip:after {
      position: absolute;
      visibility: hidden;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
      opacity: 0;
      -webkit-transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      -moz-transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition:
          opacity 0.2s ease-in-out,
          visibility 0.2s ease-in-out,
          transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      pointer-events: none;
  }
  
  /* Show the entire tooltip on hover and focus */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after,
  [data-tooltip]:focus:before,
  [data-tooltip]:focus:after,
  .tooltip:hover:before,
  .tooltip:hover:after,
  .tooltip:focus:before,
  .tooltip:focus:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
  }
  
  /* Base styles for the tooltip's directional arrow */
  .tooltip:before,
  [data-tooltip]:before {
      z-index: 1001;
      border: 6px solid transparent;
      background: transparent;
      content: "";
  }
  
  /* Base styles for the tooltip's content area */
  .tooltip:after,
  [data-tooltip]:after {
      z-index: 1000;
      padding: 10px 16px;
      width: 220px;
      background:#212121;
      border-radius: 8px;
      color: #fff;
      content: attr(data-tooltip);
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      box-shadow:0px 2px 4px rgba(0,0,0,0.33);
  }
  
  .tooltip-country:after {
      width: 120px !important;
	  text-align: center;
  }

  .tooltip-ea:after {
    white-space: pre-line !important;
    width:320px !important;
    text-align: center;
  }
  
  .plan-row .tooltip:after,
  .plan-row [data-tooltip]:after {
      text-align: center;
      height: auto;
      white-space: normal;
  }
  
  /* Directions */
  
  /* Top (default) */
  [data-tooltip]:before,
  [data-tooltip]:after,
  .tooltip:before,
  .tooltip:after,
  .tooltip-top:before,
  .tooltip-top:after {
      bottom: 100%;
      left: 50%;
  }
  
  [data-tooltip]:before,
  .tooltip:before,
  .tooltip-top:before {
      margin-left: -6px;
      margin-bottom: -12px;
      border-top-color: #212121;
      border-top-color: #212121;
      margin-top:-1px;
  }
  
  .tooltip-ea:before {
	margin-left: -10px;
	margin-bottom: -37px;
	border-top-color: #212121;
	border-top-color: #212121;
	margin-top: -1px;
  }
  
  /* Horizontally align top/bottom tooltips */
  [data-tooltip]:after,
  .tooltip:after,
  .tooltip-top:after {
      margin-left: -110px;
  }
  
  .tooltip-country:after {
      margin-left: -60px;
  }

  .tooltip-ea:after {
    margin-left: -160px;
    margin-bottom: -25px;
    z-index: 9999999;
  }
  
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after,
  [data-tooltip]:focus:before,
  [data-tooltip]:focus:after,
  .tooltip:hover:before,
  .tooltip:hover:after,
  .tooltip:focus:before,
  .tooltip:focus:after,
  .tooltip-top:hover:before,
  .tooltip-top:hover:after,
  .tooltip-top:focus:before,
  .tooltip-top:focus:after {
      -webkit-transform: translateY(-12px);
      -moz-transform: translateY(-12px);
      transform: translateY(-12px);
  }
  
  /* Left */
  .tooltip-left:before,
  .tooltip-left:after {
      right: 100%;
      bottom: 50%;
      left: auto;
  }
  
  .tooltip-left:before {
      margin-left: 0;
      margin-right: -12px;
      margin-bottom: 0;
      border-top-color: transparent;
      border-left-color: #212121;
      border-left-color: #212121;
  }
  
  .tooltip-left:hover:before,
  .tooltip-left:hover:after,
  .tooltip-left:focus:before,
  .tooltip-left:focus:after {
      -webkit-transform: translateX(-12px);
      -moz-transform: translateX(-12px);
      transform: translateX(-12px);
  }
  
  /* Bottom */
  .tooltip-bottom:before,
  .tooltip-bottom:after {
      top: 100%;
      bottom: auto;
      left: 50%;
  }
  
  .tooltip-bottom:before {
      margin-top: -12px;
      margin-bottom: 0;
      border-top-color: transparent;
      border-bottom-color: #212121;
      border-bottom-color: #212121;
  }
  
  .tooltip-bottom:hover:before,
  .tooltip-bottom:hover:after,
  .tooltip-bottom:focus:before,
  .tooltip-bottom:focus:after {
      -webkit-transform: translateY(12px);
      -moz-transform: translateY(12px);
      transform: translateY(12px);
  }
  
  /* Right */
  .tooltip-right:before,
  .tooltip-right:after {
      bottom: 50%;
      left: 100%;
      margin-top: -2px;
  }
  
  .tooltip-right:before {
      margin-bottom: 0;
      margin-left: -12px;
      border-top-color: transparent;
      border-right-color: #212121;
      border-right-color: #212121;
  }
  
  .tooltip-right:hover:before,
  .tooltip-right:hover:after,
  .tooltip-right:focus:before,
  .tooltip-right:focus:after {
      -webkit-transform: translateX(12px);
      -moz-transform: translateX(12px);
      transform: translateX(12px);
  }
  
  /* Move directional arrows down a bit for left/right tooltips */
  .tooltip-left:before,
  .tooltip-right:before {
      top: 3px;
  }
  
  /* Vertically center tooltip content for left/right tooltips */
  .tooltip-left:after,
  .tooltip-right:after {
      margin-left: 0;
      margin-bottom: -16px;
  }
  
  .tooltip-left:after,
  .tooltip-right:after {
      margin-left: 0;
      margin-bottom: -26px;
  }
  
  #timelineCarousel .carousel-control-prev {
     left:-7.5%;
     width:5%;
     opacity:1;
  }
  
  
  #timelineCarousel .carousel-control:hover {
    color:var(--main-color);
  }
  
  #timelineCarousel .carousel-control-next {
     right:-7.5%;
     width:5%;
     opacity:1;
  }
  
  
  #timelineCarousel .carousel-control-next:hover {
  background-image: linear-gradient(270deg, #0A0A0A 2%, rgba(10,10,10,0.00) 100%);
  }
  
  #timelineCarousel .carousel-control-prev-icon {
    background:url('../images/timeline-prev.svg');
    width:15px;
    height:26px;
  }
  
  #timelineCarousel .carousel-control-prev:hover .carousel-control-prev-icon {
    background:url('../images/timeline-prev-hover.svg');
  }
  
  #timelineCarousel .carousel-control-next-icon {
    background:url('../images/timeline-next.svg');
    width:15px;
    height:26px;
  }
  
  #timelineCarousel .carousel-control-next:hover .carousel-control-next-icon {
    background:url('../images/timeline-next-hover.svg');
  }
  
  
  #about-core-features  {
    padding-bottom:0px;
  }
  
  #about-core-features .padded-row {
    padding-bottom:136px;
    position: relative;
  }
  
  #core-values-indicators {
    position: absolute;
    bottom:-208px;
    right:0;
    left:0;
    text-align:center;
    display:block;
      margin-right: unset;
      margin-bottom: unset;
      margin-left: unset;
  }
  
  #core-values-indicators li {
    display:inline-block;
    padding:0px 10px;
  }
  
  #core-values-indicators li a {
    background: #0A0A0A;
    width:144px;
    height:144px;
    border-radius:50%;
    line-height:1;
    text-align:center;
    border:6px solid var(--body-bg);
    display:block;
    cursor:pointer;
    color:#575757;
    position: relative;
    opacity:1;
    margin-right: unset;
    margin-left: unset;
    text-indent: unset;
  }
  
  
  #core-values-indicators .active, #core-values-indicators .active:hover {
    background:var(--main-color-dark);
    color:#fff;
  }
  
  
  #core-values-indicators li a:hover {
    background-image: linear-gradient(180deg, #0A0A0A 25%, #181818 100%);
    color:#fff;
  }
  
  #core-values-indicators li a i {
    transition: none;
    line-height:144px;
  }
  
  #about-core-features .carousel-control-prev, #about-core-features  .carousel-control-next {
    width:5%;
    opacity:0.33;
  }
  
  #about-core-features .carousel-control-prev:hover, #about-core-features  .carousel-control-next:hover {
    opacity:1;
  }
  
  #about-core-features .carousel-control-prev {
    left:-10%;
  }
  
  #about-core-features  .carousel-control-next {
    right:-10%;
  }
  
  .dropdown-menu {
      transition: unset;
    }
	
	.dropdown-menu-scroll {
		overflow: hidden scroll;
		height: 400px;
	}
  
  
  .dropdown-toggle::after {
    border:0px;
  }
  
  
  .crypto-icon {
      height: 1em;
      vertical-align: baseline;
      margin-bottom: -0.1em;
  }
  
  .sik-dropdown > button.dropdown-toggle {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      width:200px;
      border:0px;
      background:transparent;
      font-family:"Din Next LT Pro",sans-serif;
      color:#fff;
      font-size:16px;
      font-weight:500;
      background:url('../images/select-down-white.svg') center right no-repeat transparent;
      padding-right:48px;
      transition:unset;
      padding-left:0px;
  }
  
  .sik-dropdown .dropdown-menu {
    width:200px;
  }
  
  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    background:transparent;
  }
  
  .filtered-divs {
    display:none;
  }
  
  .filtered-divs.active {
    display:block;
  }
  
  .label-error {
    background:rgba(255,0,0,0.12);
    border:1px solid rgba(255,0,0,0.68);
    line-height:22px;
    cursor:pointer;
  }
  
  .label-error:hover {
    background:rgba(255,0,0,0.2);
    border:1px solid rgba(255,0,0,0.88);
    color:rgba(255,0,0,1);
  }
  
  .label-error i {
    transition: unset;
  }
  
  .dedicated-filters .dropdown-toggle{
    font-size:15px !important;
  }
  
  .filter-lined-tabs li a {
    padding: 6px 10px;
    text-transform: unset;
      font-size:15px !important;
  }
  
  .dedicated-filters .font-12 {
    padding-bottom:4px;
    display:block;
  }
  
  .filter-out-of-stock .box {
      background: #161616;
      border: 1.5px solid rgba(255,255,255,0.05);
      opacity:0.33;
    filter: grayscale(100%);
  }
  
  .grayscale {
  filter: grayscale(100%);
  }
  
  .filter-out-of-stock .box:hover {
  opacity:0.5;
  }
  
  .filter-out-of-stock .box .button {
    background:#606060;
    cursor:not-allowed;
  }
  
  .filter-out-of-stock .box .button:hover {
    background:#656565;
  }
  
  .filter-out-of-stock .box:hover {
      background: #161616;
      border: 1.5px solid rgba(255,255,255,0.05);
      box-shadow: 0 0 18px 2px rgb(0 0 0 / 40%);
  }
  
  #dedicated-plans .server-box .button {
    display:block;
    text-align:center;
    padding:0px;
  }
  
  *:hover i {
    transition: unset;
  }
  
  #support-methods-list li a{
    display:inline-block;
    padding:4px 0px;
    margin:-4px 0px;
  }
  
  .showmobile {
    display:none;
  }
  
  .open-nav {
    display:block !important;
    position: absolute;
  top: 100px;
  left: 0px;
  right: 0px;
  background:black;
  padding:10px 0px 24px 0px !important;
  box-shadow:0px 2px 6px rgb(0 0 0 / 40%) !important;
  border-top:1px solid rgba(255,255,255,0.075);
  border-bottom:1px solid rgba(255,255,255,0.075);
  margin:0px !important;
  }
  
  .fixed-header .open-nav {
  border-top:1px solid rgba(255,255,255,0.0);
  }
  
  .open-nav li {
    display:block;
    width:100%;
    text-align:center;
    padding:6px 0px 6px 0px !important;
  }
  
  
  .open-nav li a.button {
    display:inline-block !important;
    padding:0px 28px !important;
    margin-left:-10px !important;
    margin-top:8px !important;
  }
  
  .open-nav li a.button:hover {
    transform: skew(-15deg) !important;
  }
  
  .open-nav li a:hover:before {
    display:none;
  }
  
  .open-nav li a:hover {
    transform:scale(1) !important;
  }
  
  
  .open-nav li a {
    font-size:16px !important;
    padding:6px 0px !important;
    display:block !important;
    text-transform: capitalize !important;
  }
  
  .open-nav li a span {
    display:inline-block !important;
    font-size:inherit !important;
    color:inherit !important;
    transition: unset;
    margin-left:6px;
  }
  
  #main-navigation.open-nav  .dropdown-menu {
    position: relative !important;
    top:unset !important;
    left:unset !important;
    bottom:unset !important;
    right:unset !important;
    transform:unset !important;
    width:100% !important;
    border-left:0px !important;
    border-right:0px !important;
    border-radius:0px !important;
    background:rgba(255,255,255,0.01);
    margin-top:12px !important;
    margin-bottom:12px !important;
  }
  
  #main-navigation.open-nav .dropdown-menu li a:hover {
    background: rgba(255,255,255,0.0) !important;
    border: 1px solid rgba(255,255,255,0.0) !important;
    transform: unset;
  }
  
  #main-navigation.open-nav a.dropdown-toggle.show:before {
    display:none !important;
  }
  
  #main-navigation.open-nav a.dropdown-toggle.show {
  transform:scale(1) !important;
  }
  
  #cta #support-methods-list a:hover {
    color:#fff;
    opacity:0.75;
  }
  
  .dropdown-toggle:focus, .dropdown-toggle:active {
    outline:0;
  }
  
  .line-through {
    text-decoration: line-through;
  }
  
  .grad-behind-icon {
    background: linear-gradient(209deg, rgba(35,37,32,0.1) 50%, rgba(216,255,165,1) 120%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index:-1;
    position: absolute;
    left:0;
    opacity:0.75;
  }
  
  .icon-relative {
    position: relative;
  }
  
  .grad-behind-icon-center {
    left:50%;
    transform:translateX(-50%);
  }
  
  .icon-text-tabs .grad-behind-icon, #home-intro-tabs .grad-behind-icon {
    filter:grayscale(100);
    opacity:0.4;
  }
  
  .icon-text-tabs a:hover .grad-behind-icon, .icon-text-tabs .active .grad-behind-icon {
    filter:grayscale(0);
  }
  
  #home-intro-tabs a:hover .grad-behind-icon, #home-intro-tabs .active .grad-behind-icon {
    filter:grayscale(0);
  }

  .hosting-filters {
    max-width:820px;
    margin:0px auto;
  }
  
  #softaculous-apps .box {
    padding:36px 10px 32px 10px !important;
    background:var(--body-bg);
    border:0px;
    position: relative;
    margin-bottom:27px;
      box-shadow:unset;
  }
  #softaculous-apps .box:hover {
    box-shadow:unset;
  }
  
  #softaculous-apps .box:before {
    position: absolute;
    top:-1px;
    bottom:-1px;
    right:-1px;
    left:-1px;
    background-image: linear-gradient(225deg, rgba(255,255,255,0.175) 0%, #0B0B0B 25%, #0B0B0B 55%, rgba(255,255,255,0.175) 100%);
    content:" ";
    display:block;
    border-radius:8px;
    z-index:-1;
  }
  
  
  #softaculous-apps .col:nth-child(2n) .box:before {
  background-image: linear-gradient(135deg, rgba(255,255,255,0.175) 0%, #0B0B0B 25%, #0B0B0B 55%, rgba(255,255,255,0.175) 100%);
  }
  
  
  #softaculous-apps .box:hover:before {
  background-image: linear-gradient(225deg, rgba(151,204,80,0.5) 0%, #0B0B0B 35%, #0B0B0B 75%, rgba(151,204,80,0.5) 100%);
  }
  
  #softaculous-apps .col:nth-child(2n) .box:hover:before {
  background-image: linear-gradient(135deg, rgba(151,204,80,0.5) 0%, #0B0B0B 35%, #0B0B0B 75%, rgba(151,204,80,0.5) 100%);
  }
  
  
  #softaculous-apps .box img {
    height:36px;
  }
  
  #softaculous-apps .box img:nth-child(2) {
    display:none;
  }
  
  #softaculous-apps .box:hover img:nth-child(1) {
    display:none;
  }
  
  #softaculous-apps .box:hover img:nth-child(2) {
    display:block;
  }
  
  .cta-gradient-box-green {
    background: url(../images/bg-lines-green.svg) top right no-repeat var(--main-color-dark);
    border: 1px solid #B4E76F;
    color:rgba(255,255,255,0.8);
  }
  
  
  .cta-gradient-box-green .button.dark {
    box-shadow: 0 2px 2px 0 rgb(20 20 45 / 06%), inset 0 -2px 0 0 rgb(0 0 0 / 06%);
  }
  
  .cta-gradient-box-green a {
    color:rgba(255,255,255,0.8);
  }
  
  .cta-gradient-box-green a:hover {
    color:#fff;
  }
  
  .cta-gradient-box-green h2 small {
    color:rgba(255,255,255,0.8);
  }
  
  .cta-gradient-box-green:before {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.15) 0%, rgba(42,42,42,0.00) 93%);
  }
  
  #hosting-panel .absolute-left-parent {
    padding-left:72px;
    margin-top:34px;
  }
  
  #voice-servers .box img:nth-child(1) {
    display:inline-block;
  }
  
  #voice-servers .box img:nth-child(2) {
    display:none;
  }
  
  #voice-servers .box:hover img:nth-child(1) {
    display:none;
  }
  
  #voice-servers .box:hover img:nth-child(2) {
    display:inline-block;
  }
  
  .voice-image {
	height: 90px;
  }
  
  .text-page h4 {
    padding-top:20px;
  }
  
  .text-page ul {
    padding-bottom:20px;
  }
  
  .text-page ul li {
    margin-left:16px;
    padding:4px 0px;
  }
  
  .text-page ul li img.va-mid {
    margin-bottom:3px;
  }
  
  .no-results {
    display:none;
  }
  
  
  
  .filter-count {
    text-align:left;
    font-size:24px;
    color:#fff;
    padding-bottom:32px;
    padding-top:62px;
  }
  
  .page-game-servers .filter-count {
    display:none;
  }
  
  .page-game-servers .gamecontainer {
    display:block !important;
    position: relative !important;
  }
  
  /* Location CSS */
  
  .page-location-one {
    background:url('../images/bg-locations.webp') top -40px right -60px no-repeat var(--body-bg);
  }
  
  .page-all-locations {
    background:url('../images/bg-all-locations.webp') top 0px right -60px no-repeat var(--body-bg);
  }
  
  
   @media only screen and (min-width: 1240px) and (max-width: 1539px)  {
  
     .page-location-one {
       background:url('../images/bg-locations.webp') top -40px right -60px no-repeat var(--body-bg);
       background-size:1600px
     }
   }
  
  .font-16, .location-hero, .location-hero p, .location-hero ul {
    font-size:16px !important;
  }
  
  .location-hero {
    padding:0px !important;
  }
  
  .location-hero .container {
    padding-top: 118px;
    padding-bottom: 3rem !important;
  }
  
  
  .location-hero .absolute-left {
    top:40px;
    left:15px;
  }
  
  .back-label {
    background:rgba(62,62,62,0.5);
    color:rgba(255,255,255,0.33);
  }
  .back-label:hover {
    background:rgba(62,62,62,0.66);
    color:rgba(255,255,255,0.66);
  }
  
  .back-label:focus {
    background:var(--main-color);
    color:#fff;
  }
  
  #location-specs .box {
    padding:40px;
  }
  
  #location-specs .box h4 {
    line-height:38px;
    display:block;
  }
  
  #location-specs .box h4:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
  }
  
  
  .bullet-list {
    margin-bottom:-12px;
  }
  
  .bullet-list li{
    background:url('../images/bullet-grey.svg') 0px 9px no-repeat;
    padding-left:20px;
    margin:12px 0px;
  }
  
  #offered-services .quick-links .pull-right {
    line-height:44px !important;
  }
  
  #offered-services .quick-links li:hover .icon-relative{
    margin-left:12px;
  }
  
  #offered-services .quick-links i {
    color:#D1D1D1;
  }
  
  #offered-services .quick-links li:hover i {
    color:var(--main-color);
  }
  
  #offered-services .quick-links .grad-behind-icon {
      filter: grayscale(100);
      opacity: 0.25;
  }
  
  #offered-services .quick-links li:hover .grad-behind-icon  {
    filter:unset;
  }
  
  #location-partners {
    padding-top:156px;
    margin-top:-60px;
  }
  
  #location-partners .col img {
    max-width:100%;
  }
  
  #location-partners .col {
    margin-bottom:60px;
  }
  
  #location-partners .row {
    margin-bottom:-60px;
  }
  
  .quote-form label {
    color:#fff;
    font-weight:600;
    display:block;
    padding-bottom:12px;
  }
  
  .form-control {
    border:1px solid rgba(151,151,151,0.33);
    height:56px;
    line-height:54px;
    border-radius:8px;
    padding:0px 20px 0px 46px;
    background:transparent;
    font-family: 'Titillium Web', 'ProximaNova', 'Proxima Nova', Verdana, Arial;
    color:var(--main-text-color);
    margin-bottom:30px;
  }
  .form-control::placeholder {
    color:rgba(194,194,194,0.66);
  }
  
  .textarea {
    height:188px;
    padding-left:20px !important;
  }
  
  .submit-button {
    border:0px;
  }
  
  .form-control.select {
    background:url('../images/select-down.svg') center right 20px no-repeat #0F0F0F;
    border:1px solid rgba(151,151,151,0.33);
  }
  
  .form-control:focus {
    background:#0F0F0F;
    outline:none;
    border:1px solid rgba(138,187,72,0.75);
    box-shadow:0px 0px 6px rgba(138,187,72,0.4);
    color:#fff;
  }
  
  .input-wrapper {
    position: relative;
    display:block;
  }
  .input-wrapper i {
    position: absolute;
    top:20px;
    left:20px;
  }
  
  .fake-input {
    padding-left:20px !important;
    cursor:not-allowed;
    border:1px solid rgba(151,151,151,0.33);
  }
  
  #location-pictures {
    position: relative;
  }
  
  #carouselPicturesControls {
    position: absolute;
    top:0px;
    right:0px;
    bottom:0px;
    width:50%;
  }
  
  
  #carouselPicturesControls .carousel-inner,
  #carouselPicturesControls .carousel-item  {
    position: absolute;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    background-size:cover !important;
  }
  
  
  #carouselPicturesControls img {
    width:100%;
      position: absolute;
      top:0px;
      left:0px;
      right:0px;
      bottom:0px;
  }
  
  .carousel-caption {
    position: absolute;
    top:20px;
    left:20px;
    background:rgba(0,0,0,0.4);
    border-radius:36px;
    height:36px;
    line-height:36px;
    display:inline-block;
    right:unset;
    padding:0px;
    padding-left:130px;
    padding-right:30px;
    font-weight:600;
  }
  
  
  .location-carousel-controls {
    position: absolute;
    top:18px;
    left:20px;
    border-radius:36px;
    height:36px;
    line-height:36px;
    padding:0px 20px;
    display:inline-block;
    right:unset;
  }
  
  .location-carousel-controls .carousel-control-prev,
  .location-carousel-controls .carousel-control-next,
  .location-carousel-controls .carousel-indicators {
    position: relative;
    top:unset;
    left:unset;
    right:unset;
    bottom:unset;
    display:inline-block;
    width:auto;
    float:left;
    opacity:1;
  }
  
  .location-carousel-controls .carousel-control-prev-icon,
  .location-carousel-controls .carousel-control-next-icon {
    background-size:15px;
    width: 16px;
    height: 16px;
    vertical-align:middle;
  }
  
  .location-carousel-controls .carousel-indicators {
    margin:0px 6px !important;
  }
  
  .location-carousel-controls .carousel-indicators [data-bs-target] {
    width:8px;
    height:8px;
    border-radius:50%;
    border:0px;
    margin:0px 3px;
    opacity:0.44;
    vertical-align:middle;
  }
  
  
  .location-carousel-controls .carousel-indicators [data-bs-target]:hover {
    opacity:0.75;
  }
  
  #carouselPicturesControls .carousel-indicators .active {
    background:var(--main-color);
    opacity:1;
  }
  
  #offered-services .icon-relative i {
    width:55px;
    text-align:center;
  }
  
  #support-methods-list i {
    width:25px;
    text-align:center;
  }
  
  #location-partners .col img {
    opacity:0.66;
  }
  
  #location-partners .col:hover img {
    opacity:1;
  }
  
  #all-locations-list .col {
    margin-top:40px;
  }

  .fade {
    transition: opacity 0.9s cubic-bezier(0.9, 1, 0.9, 1) !important;
  }

  /* Custom animations */

  .fader {
    opacity: 1;
  
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: linear(0.15, 1, 0.15, 1);
  }

  .fader.fast { 
    transition-duration: .3s;
    transition-timing-function: linear(0.3, 1, 0.3, 1);
  }

  .fader.hide {
    opacity: 0;
  }

  .slider, .slider * {  
    max-height: 1000vh !important;

    transform: scaleY(1);    
    transform-origin: top;
    transition: transform 0.3s ease, max-height 0.3s ease;
  }

  .slider.fast, .slider.fast * {  
    transition: transform 0.15s ease, max-height 0.15s ease;
  }

  .slider.closed, .slider.closed * {
    overflow-y: hidden;
    max-height: 0 !important;

    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.3s ease, max-height 0.3s ease;
  }

  .slider.fast.closed, .slider.fast.closed * {
    transition: transform 0.15s ease, max-height 0.15s ease;
  }

  .nav-link p {
    color: var(--main-text-color) !important;
  }
  
  .ip-name {
	 display: inline;
  }

  .ip-container {
    margin: auto;
    width: 50%;
    border: 1px solid #2c2c2c;
    border-radius: 10px;
    padding: 10px 0 10px 0;
  }
  
  .copy-icon {
	padding-left: 10px;
	position: absolute;
	margin-top: 2px;
	cursor: copy;
  }
  
  .copied-message {
	  position: absolute;
	  padding-left: 25px;
	  margin-top: 2px;
  }
  
@keyframes glow {
  0%, 100% {
    text-shadow: 0 0 10px #916d18, 0 0 20px #916d18, 0 0 30px #916d18;
  }
  50% {
    text-shadow: 0 0 20px #79590d, 0 0 30px #79590d, 0 0 40px #79590d;
  }
}

@keyframes bounce-wiggle {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-2px) rotate(-3deg);
  }
  50% {
    transform: translateY(0) rotate(3deg);
  }
  75% {
    transform: translateY(-1px) rotate(-1deg);
  }
}

.animated-party {
  display: inline-block;
  animation: glow 1.5s infinite ease-in-out, bounce-wiggle 2s infinite ease-in-out;
}