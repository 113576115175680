@media (min-width: 1px) and (max-width: 767px) {
  .hero-bgs {
	margin-left: 0px;
	background-position: top 40px center;
	background-repeat: no-repeat;
	background-color: var(--body-bg);
	opacity: 0.8;
	background-size: 800px;
  }
  .hero-bgs.cs2 {
    background-image:url('../images/banner-bg-cs2.webp');
  }
  .hero-bgs.icarus {
    background-image:url('../images/banner-bg-icarus.webp');
  }
  .hero-bgs.dedi-1 {
    background-image:url('../images/banner-bg-dedi.webp');
  }
  .hero-bgs.vrising {
    background-image:url('../images/banner-bg-vrising.webp');
  }
  .hero-bgs.sevendays {
    background-image:url('../images/banner-bg-minecraft.webp');
  }
  .hero-bgs.thefront {
    background-image:url('../images/banner-bg-thefront.webp');
  }
  .hero-bgs.hell {
    background-image:url('../images/banner-bg-cloud-gaming.webp');
  }
  .hero-bgs.blackfriday24 {
    background-image:url('../images/banner-bg-blackfriday24-mobile.webp');
  }
  .hero-bgs.xmas24 {
    background-image:url('../images/banner-bg-xmas24-mobile.webp');
  }
  
  body.page-game-servers {
    background:url('../images/bg-gameservers.webp') top center no-repeat var(--body-bg);
    background-size:1100px;
  }
  
  body.page-dedicated {
    background:url('../images/bg-dedicated.webp') top -40px center no-repeat var(--body-bg);
    background-size:1300px;
  }
  
  body.page-hosting {
      background:url('../images/bg-hosting.webp') top -40px center no-repeat var(--body-bg);
      background-size:1200px;
    }
  
  body.page-voice {
      background:url('../images/bg-voice.webp') top -40px center no-repeat var(--body-bg);
      background-size:1000px;
    }
  
  body.page-cloud {
    background:url('../images/bg-cloud.webp') top center no-repeat var(--body-bg);
    background-size:1000px;
  }

  
  body.page-about {
    background:url('../images/bg-about.webp') top center no-repeat var(--body-bg);
    background-size:900px;
  }
  
  body.page-affiliates {
    background:url('../images/bg-affiliate.webp') top -20px center no-repeat var(--body-bg);
    background-size:1200px;
  }
  
  body.page-game-servers {
  background-size:900px !important;
  background-position: top center !important;
  }
    
  body.page-game-inner {
    background-position: top center;
    background-color: var(--body-bg);
    background-repeat: no-repeat;
	background-position: 77% 0 !important;
  }
  
  img {
    max-width:100%;
  }
  
  .back-nav-games {
	display: none;
  }
  
  .back-nav {
	display: none;
  }
  
  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
      --bs-gutter-x: 2.5rem;
    }
  
    .button_container {
      display:block;
    }
  
  h1 {
    font-size:36px;
    line-height:44px;
  }
  
  h2 {
    font-size:28px;
    line-height:40px;
  }
  
  h3 {
    font-size:20px;
    line-height:28px;
  }
  
  h4 {
    font-size:20px;
    line-height:26px;
  }
  
  h5 {
    font-size:16px;
    line-height:24px;
  }
  
  section {
    padding:72px 0px;
  }
  
  .m-order-1 {
    order:1;
  }
  
  .m-order-2 {
    order:2;
  }
  .m-order-3 {
    order:3;
  }
  
  .font-18 {
      font-size: 16px;
      line-height: 26px;
  }
  
  .font-20 {
      font-size: 18px;
      line-height:28px;
  }
  
  .font-22 {
      font-size: 20px;
      line-height:28px;
  }
  
  .font-24 {
      font-size: 22px;
      line-height: 30px;
  }
  
  .font-28 {
      font-size: 24px;
      line-height:32px;
  }
  
  .font-36 {
      font-size: 30px;
      line-height: 34px;
  }
  
  .font-40 {
    font-size:36px;
    line-height:42px;
  }
  
  
  .ptfix {padding-top:0px !important;}
  .pbfix {padding-bottom:0px !important;}
  .plfix {padding-left:0px !important;}
  .prfix {padding-right:0px !important;}
  
  .plfix.col {padding-left:15px !important;}
  .prfix.col {padding-right:15px !important;}
  
  .mtfix {margin-top:0px !important;}
  .mbfix {margin-bottom:0px !important;}
  .mlfix {margin-left:0px !important;}
  .mrfix {margin-right:0px !important;}
  
  .myfix {margin-top:0px !important;margin-bottom:0px !important;}
  .mxfix {margin-right:0px !important;margin-left:0px !important;}
  
  
  .banner h1 {
    font-size:36px;
    line-height:44px;
  }
  
  
  .col {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      flex-basis: 100%;
      text-align:center;
  }
  
  .header .col {
    text-align:left;
  }
  
  .banner-tagline {
	display: none;
  }
  
  .header .col {
      -ms-flex: 0 0 304px !important;
      flex: 0 0 304px !important;
      max-width: 304px !important;
      width: 304px !important;
      flex-basis: 304px !important;
  }
  
  .header .ml-auto {
      -ms-flex: 0 0 auto !important;
      flex: 0 0 auto !important;
      max-width: auto !important;
      width: auto !important;
      flex-basis: auto !important;
  }
  
  .logo {
    height:52px;
  }
  
  .header {
    top:40px;
    padding:24px 0px;
  }
  
  .header-black {
	background-color: black;
  }
  
  .fixed-header {
	top: 46px;
    padding:18px 0px;
  }
  
  
  
  #main-navigation {
    display:none;
    float:none;
  }
  
  .game-header-name {
	white-space: normal;
  }
  
  #main-navigation li.divider {
    display:none;
  }
  
  #hero .carousel-inner {
    padding-bottom:0px;
  }
  
  #hero .carousel-indicators {
    display:none;
  }
  
  #hero .carousel-indicators .col {
    width:33.3333%;
  }
  
  .hero-slider-right-content {
    position: relative;
    top:unset;
    right:unset;
    bottom:unset;
    text-align:center;
    padding-top:40px;
  }
  
  .hero-slider-right-content img.block {
    margin:0px auto !important;
  }
  
  .hero-slider-right-content img.mt-3 {
    margin:12px auto !important;
  }
  
  .hero-home .carousel-item {

    text-align:center;
  }
  
  .showmobile {
    display:block;
  }
  
  img.showmobile {
    margin:0px auto;
  }
  
  .hidemobile {
    display:none;
  }
  
  #home-intro-tabs .tab-content {
    margin-top:30px ;
  }
  
  #intro-tabs-2,
  #intro-tabs-3 {
    display:block !important;
    opacity:1;
    margin-top:40px;
  }
  
  
  #home-control-panel {
    padding-top:72px !important;
      background:rgba(23,23,23,0.5);
  }
  
  #home-locations {
    padding-top:72px !important;
  }
  
  #home-control-panel h2.pull-left, #home-control-panel .pull-right {
    float:none !important;
    text-align:center;
  }
  
  .tab-line-start {
    display:none;
  }
  
  .icon-text-tabs .nav-item {
    padding:4px 2px;
  }
  
  .icon-text-tabs .nav-item a {
    padding:6px 12px;
    text-transform: inherit;
    font-size:11px;
    display:block;
    height:36px;
    border-radius:8px;
  }
  
  .icon-text-tabs .nav-item:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0
  }
  
  
  .icon-text-tabs .nav-item .active, .icon-text-tabs .nav-item .active:hover {
    background:var(--main-color-dark);
    color:#fff;
  }
  
  .icon-text-tabs div a .block {
	margin-top: 0px;
  }
  
  .icon-text-tabs .nav-item .icon-relative {
    float:left;
  }
  
  .icon-text-tabs .nav-item a i {
    font-size:20px !important;
    float:left;
    margin-bottom:0px;
    line-height: 24px;
    margin-right:6px;
  }
  
  .icon-text-tabs .nav-item a span.block {
    display:inline-block !important;
    float:left;
    line-height: 24px;
  }
  
  .icon-text-tabs:before,
  .icon-text-tabs .nav-item .active:before,
  .icon-text-tabs .nav-item .active:after {
    display:none;
  }
  
  .map, .map-has-list .map {
    width:348px;
    height:165px;
    background-size:100%;
    margin:0px auto;
  }
  
  .pin {
      width: 12px;
      height: 12px;
  }
  
  .pin:hover, .pin.active {
      width: 12px;
      height: 12px;
  }
  
  .pin-hover {
    font-size:12px;
    line-height:20px;
    width: 154px;
  }
  
  .pin-hover .font-14 {
    font-size:12px !important;
    line-height:20px !important;
  }
  .pin-hover .mb-n1 {
    margin-bottom:0px !important;
  }
  
  .pin-hover .font-11 {
    font-size:9px !important;
    margin-bottom:1px;
  }
  
  #testimonials-slider-boxes .col {
    display:none;
  }
  
  #testimonials-slider-boxes .col:nth-child(1) {
    display:block;
    min-height:248px;
  }
  #testimonials-slider-boxes .col:nth-child(1) img.block.mb-2 {
    margin:0px auto 10px auto;
  }
  
  
  #testimonials-slider-boxes .first-row {
    padding:0px 20px;
  }
  
  .slider-boxes {
    width:100%;
    margin:0 0%;
      flex-basis: 100%;
  }
  
  #testimonials-slider-boxes .carousel-control-prev {
    left:-10px;
  }
  
  #testimonials-slider-boxes .carousel-control-next {
    right:-10px;
  }
  
  h2 br {
    display:none;
  }
  
  #home-features .main-col.col-lg-4, #home-features .main-col.col-lg-6 {
    margin-right:0%;
    width:100%;
  }
  
  #home-features i.font-40 {
    display:block;
    margin:0px auto 20px auto !important;
    font-size:50px;
    line-height:55px;
  }
  
  .enlarged {
	border-radius: 10px;
	}
  
  .m-block {
    display:block;
  }
  
  .font-55 {
      font-size: 50px;
      line-height: 55px;
  }
  
  #home-features p.pt-2, #home-features p.pt-1 {
    padding-top:5px !important;
    padding-bottom:0px;
  }
  
  .features .col .col {
    margin-bottom:0px;
    margin-top:20px;
  }
  
  #home-features .main-col.has-divides:after, #home-features .main-col.has-divides:after, #home-features .main-col.has-divides:after {
    display:none;
  }
  
  #cta strong.font-18,
  #cta .cta-link-list {
    display:none;
  }
  
  .bg-cta-game-list {
    background: url(../images/bg-cta-game-list.webp) center center no-repeat var(--main-color);
    background-size: cover;
  }
  
  #footer-links {
    margin-top:30px;
  }
  
  #footer-links ul {
    display:none;
    transition: unset;
    margin-bottom:20px;
  }
  
  #footer-links h5 {
    font-size: 16px;
    text-transform: capitalize;
    font-weight:600;
    font-family:"Titillium Web",sans-serif;
    cursor:pointer;
  }
  
  #footer-links h5:hover, #footer-links h5.active {
    color:var(--main-color);
  }
  
  #footer-links h5:hover:after, #footer-links h5.active:after {
    background: url(../images/select-down.svg) center bottom 1px no-repeat;
    background-size:80%;
  }
  
  #footer-links h5.active:after {
    transform:rotate(180deg);
    background: url(../images/select-down.svg) center top 1px no-repeat;
    background-size:80%;
  }
  
  #footer-links h5:after {
    display: inline-block;
    content: " ";
    background: url(../images/select-down-white.svg) center bottom 1px no-repeat;
    width: 13px;
    height: 15px;
    background-size:80%;
    margin-left: 8px;
  }
  
  .tp-line-one {
    display:block;
    padding-bottom:4px;
  }
  
  .footer-secondary .showmobile {
    display:inline-block;
  }
  
  .showmobile.icon-relative {
    display:inline-block;
  }
  
  .footer-secondary .col.text-right {
    padding-top:20px;
  }
  
  #copyright .col.ml-auto {
    font-size:12px;
    padding-top:8px;
  }
  
  #copyright .col.ml-auto ul {
    float:none !important;
  }
  
  .footer {
    padding-top:68px;
  }
  
  .footer-secondary {
    margin-top:68px;
  }
  
  #feature-support-methods-list {
    padding-top:12px;
  }
  
  #feature-support-methods-list li {
    padding:8px 0px;
  }
  
  .open-nav-fix {
    top:85px;
  }
  
  .fixed-header #main-navigation {
    margin:0px 0px;
  }
  
  .sub-hero {
    padding-top:26px;
  }
  
  .filterselect-main {
  }
  
  .game-search {
    border-radius: 68px;
      height: 68px;
    font-size: 16px;
    background:url('../images/search.svg') center left 32px no-repeat rgba(0,0,0,0.5);
    background-size:18px;
    padding: 0px 40px 0px 60px;
  }
  
  .filterselect-main {
    background:url('../images/select-down.svg') center right 20px no-repeat transparent;
    font-size: 16px;
    padding: 0px 32px 0px 20px;
  }
  
  
  #games-trustpilot  {
    font-size: 16px;
  }
  
  #games-trustpilot .tp-line-one {
    padding-bottom:12px;
  }
  
  
  #games-trustpilot .tp-logo {
    height:24px;
    margin-bottom:2px;
  }
  
  .sub-hero p, #hero-about p {
    font-size: 18px;
    line-height: 28px;
  }
  
  #games-tabs .lined-tabs a {
      font-size: 16px;
      text-transform: capitalize;
      padding: 12px 14px;
  }
  
  #games-tabs .col {
    width:50%;
    flex:0 0 50%;
    padding:0px 10px;
    margin-bottom:20px;
  }
  
  #games-tabs .row {
    margin-bottom:-20px;
  }
  
  .game-box-large {
    min-height:280px;
    background-size:cover !important;
  }
  
  .game-box-bottom {
    left:0;
    right:0;
    font-size:14px;
    line-height:20px;
  }
  
  
  #games-rows .col {
    width:48.5%;
    flex:0 0 48.5%;
    padding:0px 10px;
      margin:-1px 0px 20px 0px !important;
    background-size:cover;
  }
  
  #games-rows .row {
    margin:0px -10px;
    margin-bottom:-20px;
    padding:0px 0px;
  }
  
  .game-box {
    min-height:240px;
  }
  
  .m-w-100 {
    width:100% !important;
      margin-bottom:-20px;
  }
  
  .m-w-100 .col {
    margin-bottom:20px;
  }
  
  #cloud-plans .filter-row {
    margin:0px auto;
      text-align: center;
  }
  
  .server-box .col {
      margin-bottom:13px;
  }
  
  .server-box h3 img {
    display:block;
    margin:0px auto 15px auto !important;
  }
  
  .server-box .row.mt-3 {
    margin-bottom:-13px;
  }
  
  .col-server-price {
	text-align: center !important;
  }
  
  .col-cloud-price h3, .col-server-price h3 {
    font-size:24px;
  }
  .col-server-price span{
  padding-bottom:6px;
  }
  
  .col-server-price h3 {
    padding-bottom:6px;
  }
  .server-sale-price-old {
	font-size: 18px;
	display: block;
  }
  .title p {
    font-size:18px;
    line-height:28px;
  }
  
  .showdesktop {
    display:none;
  }
  
  .panel-tab-box {
    padding:14px 24px 14px 24px;
    border-left:0px;
    background:rgba(255,255,255,0.02);
    border-top:2px solid rgba(23,23,23,0);
    margin-top:4px;
  }
  
  .panel-tab-box:hover {
    border-left:0px;
  }
  
  .panel-tab-box.active {
  border-left:0px;
  background:rgba(255,255,255,0.03);
  border-top:2px solid var(--main-color);
  }
  
  .panel-tab-box.active:after {
    display:none;
  }
  
  #ddos-protection .col.text-right {
    margin-top:26px;
  }
  
  .title.text-left {
    text-align:center !important;
  }
  
  .accordion-button {
    height:auto !important;
    background:none;
    line-height:24px !important;
      padding: 14px 54px 14px 24px;
  }
  
  .accordion-body {
    text-align:left;
  }
  
  #faq-one-third .col.plfix {
    margin-top:40px;
  }
  
  .sik-dropdown > button.dropdown-toggle {
      text-align: center;
      padding-right: 0px;
  }
  
  .sik-dropdown .dropdown-item {
    text-align:left;
  }
  
  #dedicated-plans .filter-row {
    position: relative;
    padding-bottom: 2.5rem !important;
  }
  
  #dedicated-plans .container:nth-child(1) {
  border-top:1px solid rgba(255,255,255,0.12);
  padding-top:15px;
  }
  
  #dedicated-plans .filter-row .pull-right{
    float:none !important;
    display:block;
  }
  #dedicated-plans .text-right, #dedicated-plans .text-left {
    text-align:center !important;
  }
  
  .reset-filters-fix {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .reset-filters-fix .font-12 {
    display:none !important;
  }
  
  #dedicated-plans .filter-row .lined-tabs li a {
    font-size:14px !important;
  }
  
  #dedicated-plans .filter-row .col-sm-6 {
    width:50%;
    flex:0 0 50%;
  }
  
  #dedicated-plans .filter-row .col-sm-6 .sik-dropdown > button.dropdown-toggle {
    width:100%;
    display:block;
    margin-top:-8px;
  }
  
  #dedicated-plans .filter-row.hosting-filters .col-sm-6 {
    width:100% !important;
    flex:0 0 100% !important;
  }
  
  #dedicated-plans .filter-row .m-order-3 {
    border-top:1px solid rgba(255,255,255,0.12);
    border-bottom:1px solid rgba(255,255,255,0.12);
    margin-top:14px;
    padding-top:14px;
  }
  
  .col-server-details .pull-left {
    text-align:center;
    float:none !important;
    display:block;
  }
  
  .plan-labels-list li {
    border-width:1px;
    height: 24px;
    line-height: 22px;
    padding:0px 8px;
    font-size:12px;
    margin-right:6px;
  }
  
  #dedicated-os .col {
    width:50%;
    flex:0 0 50%;
    margin-bottom:20px;
  }
  
  #dedicated-os .row {
    margin-bottom:-20px;
  }
  
  
  #dedicated-os .box {
    padding: 28px 14px;
  }
  
  #dedicated-os .box img {
    height:40px;
    margin-bottom: 0.5rem !important;
  }
  
  #dedicated-os .box .font-18 {
    font-size:16px !important;
  }
  
  .cta-gradient-box {
    padding:48px 24px;
  }
  
  .cta-gradient-box .font-18 {
    font-size:16px !important;
  }
  
  #support-methods-list {
    padding-top:20px;
  }
  
  #support-methods-list li {
    padding:4px 0px !important;
  }
  
  #testimonial-slider .carousel-control-prev, #testimonial-slider .carousel-control-next {
    bottom:unset;
    top:66.6666%;
  }
  
  .mobile-icon-height {
    height:220px;
    margin-bottom:30px;
  }
  
  .games-inner-logo {
    display:none;
  }
  
  #hero-inner, .hero-text-left p, .hero-text-left h1 {
    text-align:center !important;
  }
  
  #hero-inner p.font-18 {
    font-size:16px !important;
    line-height:24px !important;
  }
  
  
  #hero-inner .prfix {
    padding-bottom:30px;
  }
  
  
  #game-details-list {
    margin-bottom:12px !important;
  }
  
  #game-details-list li:nth-last-child(1), #game-details-list li:nth-last-child(2):after, #game-details-list li:nth-child(1):before {
    display:none;
  }
  
  .padded-row-big {
    padding:0px;
  }
  
  .pin-hover {
    margin-top: -55px;
    margin-left:6px;
  }
  
  #locations .title {
    margin-bottom:30px;
  }
  
  #features .absolute-left-parent {
    padding-left:0px;
  }
  
  #features div.absolute-left {
    position: relative;
    top:unset;
    left:unset;
    margin:0px auto 12px auto;
    height:60px;
    width:60px;
    text-align:center;
  }
  
  #features .font-60 {
    font-size:50px !important;
    line-height:55px;
  }
  
  #powerful-hardware {
    margin-bottom:-25px;
  }
  
  #powerful-hardware .col {
    margin-bottom:25px;
  }
  
  
  #powerful-hardware p.pt-3 {
    padding-top:6px !important;
  }
  
  #cta strong.font-14.italic {
    margin-top:15px;
  }
  
  #about-three-features .col {
    margin-bottom:40px;
  }
  
  #about-three-features .row {
    margin-bottom:-40px;
  }
  
  #about-core-features .col.plfix {
    padding-top:40px;
  }
  
  #about-core-features .padded-row {
    padding-bottom: 80px;
  }
  
  #core-values-indicators {
    bottom: -114px;
  }
  
  #core-values-indicators li a {
    width:64px;
    height:64px;
    line-height:64px;
    font-size:30px;
  }
  
  #core-values-indicators li a i {
    font-size:30px !important;
    line-height:64px !important;
  }
  
  #core-values-indicators li {
    padding:0px 4px;
  }
  
  .padded-row {
    padding:0px;
  }
  
  #about-statistics .col {
    width:50%;
    flex:0 0 50%;
    text-align:left;
    margin-bottom:20px;
  }
  
  
  #about-statistics .row {
    margin-bottom:-20px;
  }
  
  .stat-box .font-48 {
    font-size:30px !important;
    line-height:40px !important;
  }
  
  .stat-box{
    min-height:240px;
    font-size:14px;
    line-height:22px;
    padding:32px 15px;
  }
  
  #quick-links .col.pl-5 {
    padding-top:10px !important;
  }
  
  .quick-links {
    text-align:left;
    margin-top:-1.5px;
  }
  
  .quick-links li a {
    font-size:18px;
    height: 72px;
    padding: 14px 26px 14px 20px;
  }
  
  .quick-links li .font-44, .quick-links li .font-18 {
    line-height:36px;
  }
  
  
  .quick-links li .font-44 {
    font-size:36px !important;
  }
  
  .sub-hero h1 br {
    display:none;
  }
  
  #affiliate-hiw .col {
    text-align:left;
  }
  
  .hiw-step-3 {
    display:none;
  }
  
  .hiw-step-1{
    top:96px;
  }
  
  .hiw-step-2 {
      background: url(../images/step-1-line.svg);
      width: 11px;
      height: 105px;
      position: absolute;
      left: 24px;
      top: 82px;
  }
  
  .aff-box {
    margin-top:40px;
  }
  
  .cta-dbl-btn .col.text-right {
    text-align:center !important;
  }
  
  .cta-dbl-btn .button {
    width:240px;
    text-align:center;
    margin-left:auto !important;
    margin-right:auto !important;
  }
  
  
  #cta #support-methods-list {
    margin-top:20px;
  }
  
  .home-intro-tab-bg {
    display:none;
  }
  
  
  .page-game-servers #green-bar-nav {
    display:none;
  }
  
  
  #softaculous-apps {
    padding:0px 5px;
    margin-top:30px;
  }
  
  #softaculous-apps .col {
    width:33.3333%;
    padding:0px 10px;
    flex:0 0 33.3333%;
  }
  
  #softaculous-apps .box {
    font-size:14px;
    padding:28px 10px 24px 10px !important
  }
  
  #softaculous-apps .box img {
    margin-bottom:0.5rem !important;
  }
  
  
  #softaculous-apps .col:nth-last-child(1),
  #softaculous-apps .col:nth-last-child(2) {
    display:none;
  }
  
  .absolute-left-parent div.absolute-left {
    position: relative;
    top:unset;
    left:unset;
    margin:0px auto 4px auto;
    height:60px;
    width:60px;
    text-align:center;
  }
  
  #hosting-panel .absolute-left-parent {
    padding-left:0px;
  }
  
  .page-hosting #dedicated-plans .box {
    margin-bottom:20px;
  }
  
  
  #voice-servers .box {
    margin-bottom:20px;
  }
  
  #gameserver-nav li a.font-16 {
      font-size: 14px;
      padding: 0px 12px;
  }
  
  .text-page {
    text-align:center;
  }
  
  .page-legal #hero,
  .page-legal #green-bar-nav  {
    text-align:center;
  }
  
  .page-legal #gameserver-nav {
    float:none;
  }
  
  .game-box, .game-box-large {
    background-size:cover !important;
  }
  
  .pin-hover:after, .pin-hover:before {
  border-right-width:5px;
  border-left-width:5px;
  border-top-width:5px;
  }
  
  .location-nz-info {
    margin-left:-50px;
  }
  
  .location-nz-info.pin-hover:after, .location-nz-info.pin-hover:before {
    margin-left:56px;
  }
  
  .location-syd-info {
    margin-left:-50px;
  }
  
  .location-syd-info.pin-hover:after, .location-syd-info.pin-hover:before {
    margin-left:56px;
  }
  
  .location-bris-info {
    margin-left:-50px;
  }
  
  .location-bris-info.pin-hover:after, .location-bris-info.pin-hover:before {
    margin-left:56px;
  }
  
  .location-mel-info {
    margin-left:-50px;
  }
  
  .location-mel-info.pin-hover:after, .location-mel-info.pin-hover:before {
    margin-left:56px;
  }
  
  .location-jp-info {
    margin-left:-50px;
  }
  
  .location-jp-info.pin-hover:after, .location-jp-info.pin-hover:before {
    margin-left:56px;
  }
  
  .hiw-step-1, .hiw-step-2 {
    display:none;
  }
  
  #affiliate-hiw .absolute-left-parent {
    padding-left:0px;
  }
  
  #affiliate-hiw .absolute-left {
    margin-left:0px;
    display:none;
  }
  
  .aff-step {
    float:none;
    display:inline-block;
  }
  
  #cloud-swap-games {
    text-align:center;
  }
  
  
  
  /* Locations css */
  body.page-location-one {
      background: url(../images/bg-locations.webp) top -80px right -100px no-repeat var(--body-bg);
      background-size: 960px;
  }
  
  .location-hero .absolute-left {
    top:-20px;
  }
  
  .location-hero .container {
    padding-top:26px;
  }
  
  #location-specs .icon-relative {
    float:none !important;
    margin:0px auto 6px auto !important;
    display:block;
  }
  
  .bullet-list li {
    background:none;
    padding-left:0px;
  }
  
  .bullet-list li:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 8px;
      display: inline-block;
      background-color: rgba(161,161,161,0.5);
      vertical-align: middle;
  }
  
  #location-specs .col {
    margin-bottom:30px;
  }
  
  
  #location-specs .row{
    margin-bottom:-30px;
  }
  
  #offered-services .quick-links li a div.pull-right.mr-4 {
    display:none;
  }
  
  #offered-services .quick-links li a {
    padding: 14px 0px 14px 0px;
  }
  
  #location-pictures {
    padding-bottom:0px;
  }
  
  #carouselPicturesControls {
    position: relative;
    top:unset;
    right:unset;
    bottom:unset;
    display:block;
    width:100%;
    width:calc(100% + 40px);
    margin-left:-20px;
    margin-right:-20px;
    height:320px;
    margin-top:60px;
  }
  
  #location-stats .col {
    width:50%;
    flex:0 0 50%;
    margin-bottom:40px;
  }
  
  #location-stats .col:nth-last-child(1) {
    display:none;
  }
  
  #location-stats .row {
    margin-bottom:-40px;
  }
  
  #location-partners {
    padding-top: 116px;
  }
  
  #location-partners .col {
    width:33.3333%;
    flex:0 0 33.3333%;
    margin-bottom:40px;
  }
  
  #location-partners .col img {
    max-height:32px;
    max-width:100%;
    vertical-align: middle;
  }
  
  .quote-form .col {
    text-align:left;
  }
  
  
  body.page-all-locations {
    background:var(--body-bg);
  }
  
  .page-all-locations #hero {
    text-align:center;
  }
  
  .page-all-locations #hero p.w-50 {
    width:100% !important;
  }
  
  #all-locations-list {
    text-align:center;
  }
  
  .tooltip-country {
	display: block;
  }
  
.game-tags {
  height: 40px;
  margin-top: -15px;
}

  .tooltip-ea:after {
	margin-left: -155px;
	margin-bottom: -22px;
  }
  
.tooltip-ea::before {
	margin-left: -10px;
	margin-bottom: -34px;
	border-top-color: #212121;
	border-top-color: #212121;
	margin-top: -1px;
}
  
}
