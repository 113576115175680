@media (min-width: 768px) and (max-width: 1239px) {
  .hero-bgs {
	margin-bottom: -200px;
	margin-left: 0px;
	background-position: top 40px center;
	background-repeat: no-repeat;
	background-color: var(--body-bg);
	opacity: 0.8;
  }
  .hero-bgs.cs2 {
    background-image:url('../images/banner-bg-cs2.webp');
  }
  .hero-bgs.icarus {
    background-image:url('../images/banner-bg-icarus.webp');
  }
  .hero-bgs.dedi-1 {
    background-image:url('../images/banner-bg-dedi.webp');
  }
  .hero-bgs.vrising {
    background-image:url('../images/banner-bg-vrising.webp');
  }
  .hero-bgs.sevendays {
    background-image:url('../images/banner-bg-minecraft.webp');
  }
  .hero-bgs.thefront {
    background-image:url('../images/banner-bg-thefront.webp');
  }
  .hero-bgs.hell {
    background-image:url('../images/banner-bg-cloud-gaming.webp');
  }
  .hero-bgs.blackfriday24 {
    background-image:url('../images/banner-bg-blackfriday24-mobile.webp');
  }
  .hero-bgs.xmas24 {
    background-image:url('../images/banner-bg-xmas24-mobile.webp');
  }
  
  body.page-game-servers {
    background:url('../images/bg-gameservers.webp') top center no-repeat var(--body-bg);
    background-size:1400px;
  }
  
  body.page-dedicated {
    background:url('../images/bg-dedicated.webp') top -40px center no-repeat var(--body-bg);
    background-size:1550px;
  }
  
  body.page-hosting {
    background:url('../images/bg-hosting.webp') top -40px center no-repeat var(--body-bg);
    background-size:1550px;
  }
  
  body.page-voice {
    background:url('../images/bg-voice.webp') top -40px center no-repeat var(--body-bg);
    background-size:1550px;
  }
  
  body.page-cloud {
    background:url('../images/bg-cloud.webp') top -25px center no-repeat var(--body-bg);
    background-size:1700px;
  }
  
  body.page-game-inner {
    background-position: top center;
    background-color: var(--body-bg);
    background-repeat: no-repeat;
  }
  
  body.page-about {
    background:url('../images/bg-about.webp') top center no-repeat var(--body-bg);
    background-size:1400px;
  }
  
  body.page-affiliates {
    background:url('../images/bg-affiliate.webp') top -20px center no-repeat var(--body-bg);
    background-size:1500px;
  }
  
  body.page-game-servers {
    background-size:1600px !important;
    background-position: top center !important;
  }
  
  .bg-cta-game-list {
      background: url(../images/bg-cta-game-list.webp) center right -200px no-repeat var(--main-color-dark);
      background-size: 910px;
  }
  
  .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
      --bs-gutter-x: 2.5rem;
    }
  
    .game-box-large {
      min-height:360px;
      background-size:cover !important;
    }
  
  #games-rows .col-lg-25 {
    flex: 0 0 auto;
    width: 24.9%;
  }
  
  .game-box {
    min-height:274px;
    background-size:cover !important;
  }
  
  .game-header-name {
	white-space: normal;
  }
  
  
  #home-control-panel .col.ptfix {
    padding-top:0px !important;
  }
  #home-control-panel .home-panel-tabs {
    width:100%;
  float:none !important;
  clear:both;
  }
  
  .tab-line-start {
    display:none;
  }
  
  #home-control-panel .tab-content h2 {
    font-size:24px;
    line-height:32px;
    padding-top:0px !important;
  }
  
  #home-control-panel {
  padding-top:0px !important;
  }
  
  #home-control-panel h2.pull-left {
    width:100%;
  float:none !important;
  clear:both;
  text-align:center;
  }
  
  .icon-text-tabs:before {
    background-image:linear-gradient(90deg, rgba(0,0,0,0.00) 0%, #B4E76F 20%,#B4E76F 80%, rgba(0,0,0,0.00) 100%);
  }
  
  .logo {
    height:48px;
  }
  #main-navigation {
  margin:2px 0px !important;
  }
  
  #main-navigation li a {
      padding: 4px 12px;
    }
  
  .map {
    width:940px;
    height:444px;
    background-size:940px;
    margin:0px auto;
  }
  
  #testimonials-slider-boxes .slider-boxes:nth-last-child(1) {
    display:none;
  }
  
  #testimonials-slider-boxes .first-row {
    padding:0px 10px;
  }
  
  .slider-boxes {
      width: 47.5%;
      margin-right: 5%;
  }
  
  #testimonials-slider-boxes .slider-boxes:nth-last-child(2) {
    margin-right:0%;
  }
  
  .green-box {
    margin-left:0px !important;
  }
  
  #hero .carousel-inner {
    padding-bottom:184px;
  }
  
  .hero-home .container {
    padding:0px 15px !important;
  }
  
  #hero .carousel-indicators .row {
    margin:0px;
    padding:0px 10px;
  }
  
  .hero-slider-right-content {
    bottom: 182px;
    right: 20px;
  }
  
  #cloud-hero-list {
    font-size:18px !important;
  }
  
  img {
    max-width:100%;
  }
  
  .hidelg {
    display:none;
  }
  
  .map {
    margin:0px auto;
  }
  
  .cta-gradient-box {
      padding: 68px 48px;
  }
  
  #testimonial-slider .carousel-control-next {
    right:0%;
  }
  
  #testimonial-slider .carousel-control-prev {
    left:0%;
  }
  
  #ddos-protection h2.ptfix, #inner-hardware h2.ptfix, #modpacks .col.ptfix {
    padding-top:0px !important;
  }
  
  #modpacks .col.mtfix {
    margin-top:0px !important;
  }
  
  .padded-row-big {
    padding:0px 15px;
  }
  
  #powerful-hardware {
    margin-top:40px;
  }
  
  #powerful-hardware h5 i {
    display:block;
    margin-bottom:12px;
  }
  
  #hero-inner .prfix {
    padding-right:15px !Important;
  }
  
  #timelineCarousel .carousel-control-prev {
    left:5%;
    height:100px;
    top:-120px;
  }
  #timelineCarousel .carousel-control-next {
    right:5%;
    height:100px;
    top:-120px;
  }
  
  #about-core-features .padded-row {
    padding-left:0px;
    padding-right:0px;
  }
  
  #about-core-features h2.mtfix {
    margin-top:0px !important;
    padding-top:0px !important;
  }
  
  .hiw-step-3{
    display:none;
  }
  
  .aff-box .font-18 {
    font-size:16px !important;
  }
  
  #home-intro-tabs li.ml-5 {
    margin-left:0px !important;
  }
  
  .sub-hero {
      padding-top: 98px;
  }
  
  .hero-home .carousel-item {
      padding-top: 216px;
  }
  
  #gameserver-nav-order {
    display:none;
  }
  
  #gameserver-nav li a {
    padding:0px 14px;
    font-size:14px;
  }
  
  #softaculous-apps .col:nth-last-child(1),
  #softaculous-apps .col:nth-last-child(2) {
    display:none;
  }
  
  
  #one-click-apps .ptfix {
    padding-top:25px !important;
  }
  
  
  #hosting-panel .col-xl-5 .absolute-left-parent:nth-child(1) {
    margin-top:0px !important;
  }
  
  .map-has-list .col-xl-8 {
      flex: 0 0 auto;
      width: 100%;
  }
  
  .game-box-large {
    background-size:cover !important;
  }
  
  
  .game-box {
    background-size:102% !important;
  }
  
  .map-has-list .map {
      width: 940px;
      height: 444px;
      background-size: 940px;
  }
  
  
  /* Location CSS */
  
  
  .page-location-one body {
    background: url(../images/bg-locations.webp) top -80px right -100px no-repeat var(--body-bg);
    background-size: 1460px;
  }
  
  .page-all-locations body {
    background:url('../images/bg-all-locations.webp') top -0px right -260px no-repeat var(--body-bg);
    background-size: 1460px;
  }
  
  #location-specs .box {
      padding: 36px 28px;
  }
}