@media (min-width: 768) and (max-width: 991px) {
  .hero-bgs {
	margin-bottom: -150px;
	margin-left: 0px;
	background-position: top 80px center;
	background-repeat: no-repeat;
	background-color: var(--body-bg);
	opacity: 0.8;
  }
  .hero-bgs.cs2 {
    background-image:url('../images/banner-bg-cs2.webp');
  }
  .hero-bgs.icarus {
    background-image:url('../images/banner-bg-icarus.webp');
  }
  .hero-bgs.dedi-1 {
    background-image:url('../images/banner-bg-dedi.webp');
  }
  .hero-bgs.vrising {
    background-image:url('../images/banner-bg-vrising.webp');
  }
  .hero-bgs.sevendays {
    background-image:url('../images/banner-bg-minecraft.webp');
  }
  .hero-bgs.thefront {
    background-image:url('../images/banner-bg-thefront.webp');
  }
  .hero-bgs.hell {
    background-image:url('../images/banner-bg-cloud-gaming.webp');
  }
  .hero-bgs.blackfriday24 {
    background-image:url('../images/banner-bg-blackfriday24-mobile.webp');
  }
  .hero-bgs.xmas24 {
    background-image:url('../images/banner-bg-xmas24-mobile.webp');
  }
  
  body.page-game-servers {
    background:url('../images/bg-gameservers.webp') top center no-repeat var(--body-bg);
    background-size:1200px;
  }
  
  body.page-dedicated {
    background:url('../images/bg-dedicated.webp') top -40px center no-repeat var(--body-bg);
    background-size:1350px;
  }
  
  body.page-hosting {
      background:url('../images/bg-hosting.webp') top -40px center no-repeat var(--body-bg);
      background-size:1350px;
    }
  
  body.page-voice  {
      background:url('../images/bg-voice.webp') top -40px center no-repeat var(--body-bg);
      background-size:1350px;
    }
  
  body.page-cloud {
    background:url('../images/bg-cloud.webp') top -25px center no-repeat var(--body-bg);
    background-size:1300px;
  }
  
  body.page-game-inner {
    background-position: top center;
    background-color: var(--body-bg);
    background-repeat: no-repeat;
  }
  
  body.page-about {
    background:url('../images/bg-about.webp') top center no-repeat var(--body-bg);
    background-size:1100px;
  }
  
  body.page-affiliates {
    background:url('../images/bg-affiliate.webp') top -20px center no-repeat var(--body-bg);
    background-size:1300px;
  }
  
  body.page-game-servers {
    background-size:1200px !important;
    background-position: top center !important;
  }
  
  
  h1 {
    font-size: 36px;
    line-height: 44px;
  }
  
  h2 {
      font-size: 32px;
      line-height: 40px;
    }
  
  h3 {
      font-size: 22px;
      line-height: 30px;
  }
  
  .sub-hero {
    padding-top:28px;
  }
  
  .hero-home .carousel-item {
      padding-top: 146px;
  }
  
  img {
    max-width:100%;
  }
  
  .myfix {margin-top:0px !important;margin-bottom:0px !important;}
  .mxfix {margin-right:0px !important;margin-left:0px !important;}
  
  .button_container {
    display:block;
  }
  
  .header .col {
    text-align:left;
  }
  
  .header .col {
      -ms-flex: 0 0 304px !important;
      flex: 0 0 304px !important;
      max-width: 304px !important;
      width: 304px !important;
      flex-basis: 304px !important;
  }
  
  .header .ml-auto {
      -ms-flex: 0 0 auto !important;
      flex: 0 0 auto !important;
      max-width: auto !important;
      width: auto !important;
      flex-basis: auto !important;
  }
  
  .logo {
    height:56px;
  }
  
  .top-bar {
    display:none;
  }
  
  .header {
    top:0px;
    padding:24px 0px;
  }
  
  .fixed-header {
    padding:18px 0px;
  }
  
  #main-navigation {
    display:none;
    float:none;
  }
  
  .game-header-name {
	white-space: normal;
  }
  
  
  #main-navigation li.divider {
    display:none;
  }
  
  .button_container {
    top:42px;
  }
  
  .fixed-header .button_container {
    top:33px;
  }
  
  .open-nav {
    top:104px;
  }
  
  .fixed-header .open-nav-fix {
    top: 85px;
  }
  
  .hero-slider-right-content {
    bottom: 154px;
    right:15px;
  }
  
  .hero-slider-right-content span,
  .hero-slider-right-content img {
    display:none;
  }
  
  #hero .carousel-inner {
    padding-bottom:164px;
  }
  
  .hero-home .container {
    padding:0px 15px !important;
  }
  
  #hero .carousel-indicators .row {
    margin:0px;
    padding:0px 10px;
  }
  
  
  #home-intro-tabs {
    text-align:center;
  }
  #home-intro-tabs .hidemobile {
    display:none;
  }
  
  #home-intro-tabs .showmobile {
      display: block;
  }
  
  
  #home-intro-tabs .prfix {
    padding-right:15px !important;
    padding-top:0px !important;
  }
  
  #intro-tabs-2, #intro-tabs-3 {
      display: block !important;
      opacity: 1;
      margin-top: 40px;
  }
  
  .icon-text-tabs .nav-item a {
    padding:16px 20px;
  }
  
  #home-control-panel .home-panel-tabs {
    width:100%;
  float:none !important;
  clear:both;
  }
  
  .tab-line-start {
    display:none;
  }
  
  #home-control-panel .tab-content h2 {
    font-size:24px;
    line-height:32px;
    padding-top:0px !important;
  }
  
  #home-control-panel {
  padding-top:0px !important;
  }
  
  #home-control-panel h2.pull-left {
    width:100%;
  float:none !important;
  clear:both;
  text-align:center;
  }
  
  .icon-text-tabs:before {
    background-image:linear-gradient(90deg, rgba(0,0,0,0.00) 0%, #B4E76F 20%,#B4E76F 80%, rgba(0,0,0,0.00) 100%);
  }
  
  
  #testimonials-slider-boxes .carousel-item .row {
    padding:0% 5%;
  }
  
  #testimonials-slider-boxes .carousel-control-prev {
    left:-15px;
    width:5%;
  }
  
  #testimonials-slider-boxes .carousel-control-next {
    right:-15px;
    width:5%;
  }
  
  #the-home-slider {
    width:100%;
    padding:0px 20px;
  }
  
  #testimonials-slider-boxes .carousel-item .slider-boxes {
    width:48.5%;
    margin-right:3%;
  }
  
  #testimonials-slider-boxes .carousel-item .col:nth-last-child(1) {
    display:none;
  }
  
  #testimonials-slider-boxes .carousel-item .col:nth-child(2) {
    margin-right:0%;
  }
  
  #home-features i.font-40 {
    font-size:50px;
    line-height:55px;
    display:block;
    float:none;
    margin-bottom:24px;
  }
  #home-features h3 {
    font-size:20px;
  }
  
  #home-features .main-col.has-divides:after, #home-features .main-col.has-divides:after, #home-features .main-col.has-divides:after {
    display:none;
  }
  
  #home-features .row.mtfix {
    margin-top:0px !important;
      margin-bottom: -40px;
  }
  
  #home-features .main-col {
  
      margin-bottom: 40px;
  }
  
  
  .big-timer {
    font-size:30px;
    line-height:36px;
  }
  
  
  .big-timer span {
    padding:0px 2px 24px 2px;
  }
  
  #cta .font-22 {
    font-size:18px !important;
    line-height:28px !important;
  }
  
  #cta .cta-link-list li:nth-child(1) {
    display:none;
  }
  
  .tablet-clearfix:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0
  }
  
  
  .footer-logo {
    float:left;
    height:56px;
  }
  
  .social-list {
    float:right;
  }
  
  .social-list li {
    display:inline-block !important;
    font-size:14px;
    margin-right:12px;
  }
  
  .social-list li:nth-last-child(1) {
    margin-right:0px;
  }
  
  #footer-links {
    margin-top:30px;
  }
  
  .footer-secondary strong.inline-block, .footer-secondary .tp-logo {
    display:none;
  }
  
  #copyright .pull-right {
    float:none !important;
    margin-top:6px;
    font-size:12px;
  }
  
  .hidetablet {
    display:none;
  }
  
  
  .col-cloud-cpu {
      flex: 0 0 auto;
      width: 25%;
  }
  
  .col-cloud-ram {
      flex: 0 0 auto;
      width: 32.5%;
  }
  
  .col-cloud-ssd {
      flex: 0 0 auto;
      width: 22.5%;
  }
  
  .col-cloud-transfer {
      flex: 0 0 auto;
      width: 20%;
  }
  
  .col-cloud-price {
      flex: 0 0 auto;
      width: auto;
      margin-top:20px;
  }
  
  .col-cloud-order {
      flex: 0 0 auto;
      width: auto;
      margin-top:24px;
  }
  .col-cloud-order .button {
    padding:0px 24px !Important;
  }
  
  #cloud-swap-games .col.mtfix {
    margin-top:0px !important;
    padding-top:0px !important;
  }
  
  
  .panel-tab-box {
    padding:19px 24px 14px 24px;
    border-left:0px;
    background:rgba(255,255,255,0.02);
    border-top:2px solid rgba(255,255,255,0.075);
    margin-top:4px;
  }
  
  
  .panel-tab-box:hover {
  border-top:2px solid rgba(255,255,255,0.175);
  border-left:0px;
  }
  
  .panel-tab-box {
    width:25%;
    float:left;
    text-align:center;
  }
  
  #cloud-control-panel {
    text-align:center;
  }
  
  .panel-tab-box.active {
  border-left:0px;
  background:rgba(255,255,255,0.03);
  border-top:2px solid var(--main-color);
  }
  
  .panel-tab-box.active:after {
    display:none;
  }
  
  .panel-tab-box h5 {
    line-height:22px;
  }
  
  .panel-tab-box h5 i {
    margin:0px auto 10px auto;
    display:block;
  }
  
  .panel-tab-box p {
    display:none !important;
  }
  
  #ddos-protection h2.ptfix {
    padding-top:0px !important;
  }
  
  .map, .map-has-list .map {
    width:728px;
    margin:0px auto;
    height:344px;
    background-size:728px;
  }
  
  
  .pin-hover {
    font-size:14px;
    line-height:22px;
    width: 194px;
  }
  
  .pin-hover .font-14 {
    font-size:14px !important;
    line-height:20px !important;
  }
  .pin-hover .mb-n1 {
    margin-bottom:0px !important;
  }
  
  .pin-hover .font-11 {
    font-size:10px !important;
    margin-bottom:1px;
  }
  .pin-hover {
      margin-top: -110px;
      margin-left: 12px;
  }
  
  #faq-one-third .plfix {
    padding-left:15px !important;
    margin-top:40px;
  }
  
  #faq-one-third h2 br {
    display:none;
  }
  
  
  .col-lg-25 {
    width:33.1%;
  }
  
  .m-w-100 {
    width:100%;
  }
  
  .trend-game-row .col:nth-last-child(1) {
    display:none;
  }
  
  
  #game-details-list {
    margin-bottom:12px !important;
  }
  
  #game-details-list li:nth-last-child(1) {
    display:none;
  }
  
  .padded-row-big {
    padding:0px;
  }
  
  #hero-inner .font-18 {
    font-size:16px;
    line-height:24px;
  }
  #game-panel {
    text-align:center;
  }
  
  #inner-hardware h2.ptfix {
    padding-top:0px !important;
  }
  
  #powerful-hardware {
    margin-top:40px;
    margin-bottom:-45px;
  }
  
  #powerful-hardware .col {
    margin-bottom:25px;
  }
  
  #powerful-hardware p.pt-3 {
    padding-top:6px !important;
  }
  
  .cta-gradient-box {
    padding:48px 40px;
  }
  
  .cta-gradient-box #support-methods-list {
    font-size:16px !important;
  }
  
  #modpacks .col.ptfix {
    padding-top:0px !important;
    margin-top:0px !important;
  }
  
  .col-server-details {
    width:100%;
  }
  
  .col-cpu {
      flex: 0 0 auto;
      width: 25%;
  }
  
  .col-ram {
      flex: 0 0 auto;
      width: 25%;
  }
  
  .col-ssd {
      flex: 0 0 auto;
      width: 25%;
  }
  
  .col-transfer {
      flex: 0 0 auto;
      width: 25%;
  }
  
  .col-server-price {
    text-align:left !important;
    margin-top:24px;
  }
  
  .plan-labels-list li {
    font-size:12px;
    height:28px;
    line-height:26px;
    border-width:1px;
  }
  
  .col-server-price h3 {
    display:inline-block;
    padding-right:20px;
  }
  
  .col-server-price span.font-14 {
    display:none;
  }
  
  #dedicated-plans .server-box .button {
    display:inline-block;
    padding:0px 24px;
    margin-top:0px !important;
  }
  
  #dedicated-plans .filter-row {
    position: relative;
    padding-bottom: 2.5rem !important;
  }
  
  #dedicated-plans .filter-row .pull-right{
    float:none !important;
    display:block;
    text-align:left !important;
  }
  
  .reset-filters-fix {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .reset-filters-fix .font-12 {
    display:none !important;
  }
  
  #dedicated-plans .filter-row .lined-tabs li a {
    font-size:14px !important;
  }
  
  #dedicated-plans .filter-row .col-sm-6 .sik-dropdown > button.dropdown-toggle {
    width:100%;
    display:block;
  }
  
  #dedicated-plans .filter-row .col-sm-6 {
    text-align:left;
  }
  
  #dedicated-plans .sik-dropdown > button.dropdown-toggle {
    text-align:left;
      padding-right: 0px;
  }
  
  #dedicated-plans .sik-dropdown .dropdown-item {
    text-align:left;
  }
  
  #dedicated-os .col {
    margin-bottom:20px;
  }
  
  #dedicated-os .row {
    margin-bottom:-20px;
  }
  
  #testimonial-slider .carousel-control-prev, #testimonial-slider .carousel-control-next {
    bottom:-80px;
  }
  
  #ddos-protection .col.ptfix {
    padding-top:0px !important;
    margin-top:0px !important;
  }
  
  #about-core-features .padded-row {
    padding-left:0px;
    padding-right:0px;
  }
  
  
  #about-core-features .col.plfix, #about-core-features h2.ptfix {
    padding-top:0px !important;
    margin-top:0px !important;
  }
  
  #about-core-features .col.plfix {
    padding-left:15px !important;
  }
  #about-core-features .padded-row {
    padding-bottom: 80px;
  }
  
  #about-core-features  h2 {
    font-size:30px;
  }
  
  #core-values-indicators {
    bottom: -124px;
  }
  
  #core-values-indicators li a {
    width:80px;
    height:80px;
    line-height:80px;
    font-size:30px;
  }
  
  #core-values-indicators li a i {
    font-size:30px !important;
    line-height:80px !important;
  }
  
  #core-values-indicators li {
    padding:0px 4px;
  }
  
  .padded-row {
    padding:0px;
  }
  
  #about-statistics .col {
    margin-bottom:20px;
  }
  
  #about-statistics .row {
    margin-bottom:-20px;
  }
  
  #quick-links .plfix {
    padding-left:15px !important;
  }
  
  #cta #support-methods-list {
    margin-top:-17px !important;
  }
  
  .hiw-step-3 {
    display:none;
  }
  
  #affiliates-payouts .plfix {
    padding-left:15px !important;
  }
  
  .aff-box .font-68 {
    font-size:42px;
    line-height:50px;
  }
  
  .aff-box {
    padding:38px 12px 46px 12px;
  }
  
  .aff-box .font-18 {
    font-size:14px !important;
  }
  
  .cta-dbl-btn .button {
      width: 100%;
      text-align: center;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  
  
    .cta-dbl-btn .button.mr-3 {
      margin-right:0px !important;
      margin-top:-10px !important;
    }
  
  #home-control-panel .col.ptfix {
    padding-top:0px !important;
  }
  
  
  #gameserver-nav-order {
    display:none;
  }
  
  #gameserver-nav li a {
      padding: 0px 14px;
      font-size: 12px;
  }
  
  #gameserver-nav{
    margin:-6px 0px;
  }
  
  #one-click-apps, #hosting-panel {
    text-align:center;
  }
  
  #hosting-panel .col-xl-5 {
    display:none;
  }
  
  #one-click-apps .ptfix {
    padding-top:0px !important;
  }
  
  #one-click-apps .col-lg-6 {
    margin-top:30px;
  }
  
  .hosting-filters .col-sm-6 {
    text-align:center !important;
  }
  
  .game-box, .game-box-large {
    background-size:cover !important;
  }
  
  
  .map-has-list .col-xl-8 {
      flex: 0 0 auto;
      width: 100%;
  }
  
  .map-has-list .map {
    margin:0px auto;
  }
  
  
    .location-nz-info {
      margin-left:-50px;
    }
  
    .location-nz-info.pin-hover:after,
    .location-nz-info.pin-hover:before {
      margin-left:62px;
    }
  
    .location-syd-info {
      margin-left:-50px;
    }
  
    .location-syd-info.pin-hover:after,
    .location-syd-info.pin-hover:before {
      margin-left:62px;
    }
  
    .location-bris-info {
      margin-left:-50px;
    }
  
    .location-bris-info.pin-hover:after,
    .location-bris-info.pin-hover:before {
      margin-left:62px;
    }
  
    .location-mel-info {
      margin-left:-50px;
    }
  
    .location-mel-info.pin-hover:after,
    .location-mel-info.pin-hover:before {
      margin-left:62px;
    }
  
    .location-jp-info {
      margin-left:-50px;
    }
  
    .location-jp-info.pin-hover:after,
    .location-jp-info.pin-hover:before {
      margin-left:62px;
    }
  
  
  /* Location CSS **/
  
  .location-hero .plfix{
    padding-left:15px !important;
    margin-top:-30px;
  }
  
  .location-hero .absolute-left {
    top:0px;
  }
  
  .location-hero .container {
    padding-top:68px;
  }
  
  .location-hero ul li {
    width:50%;
    float:left;
  }
  
  
  .location-hero ul li:nth-child(2) {
    min-height:136px;
  }
  
  #location-specs .col:nth-last-child(1) {
    display:none;
  }
  
  #location-pictures {
    padding:68px 0px;
  }
  
  #carouselPicturesControls {
    width:47.5%;
  }
  
  #location-stats .col-lg-25 {
    width:25% !important;
  }
  
  #location-stats .col-lg-25:nth-last-child(1) {
    display:none;
  }
  
  #location-stats {
    padding:68px 0px;
  }
  
  #location-partners .col {
    width:20% !important;
    max-width: 20%;
    flex:0 0 20%;
  }
  
  #location-partners .col:nth-last-child(1),
  #location-partners .col:nth-last-child(2) {
    display:none;
  }
  
  body.page-location-one {
    background: url(../images/bg-locations.webp) top -80px right -100px no-repeat var(--body-bg);
    background-size: 1260px;
  }
  
  #location-specs .box {
      padding: 36px 28px;
  }
  
  
  .location-hero p {
    max-width:100%;
  }
  
  
  body.page-all-locations {
    background:url('../images/bg-all-locations.webp') top -0px right -360px no-repeat var(--body-bg);
    background-size: 1460px;
  }
  
  .page-all-locations #hero p.w-50 {
    width:100% !important;
  }
}